import { useState, useEffect } from 'react';
import {  FormControl, Select, MenuItem, InputLabel} from "@mui/material";


const ListViewSelector = (props) => {
    const {currentUser, displaySetting, setDisplaySetting, loading} = props
    const [displayOptions,setDisplayOptions] = useState([
        {text:"Only Mine", value:"user"},
    ]);

    useEffect(() => {
        if (currentUser){
            const options = [{text:"Only Mine", value:"user"}];

            if (currentUser.user.userAttributes.findIndex(x => x.value && x.attribute.attributeName === 'Manager') >= 0){
                options.push({text:"My Direct Reports", value: "team"})
                options.push({text:"Mine + Direct Reports", value: "team+"})
            }

            if (currentUser.user.userAttributes.findIndex(x => x.value && x.attribute.attributeName === 'Admin Users') >= 0) {
                options.push({text:"All Users", value: "all"})
            }

            setDisplayOptions(options);
        }
    },[currentUser])

    return (currentUser && (currentUser.user.userAttributes.findIndex(x => x.value && (x.attribute.attributeName === 'Manager' || x.attribute.attributeName === 'Admin Users')) >= 0)) ? (
        <FormControl variant='standard' style={{marginLeft: "30px", minWidth: "120px"}}>
                <InputLabel>For User(s)</InputLabel>
                <Select
                    color="primary"
                    value={displaySetting}
                    onChange={(e) => {
                        setDisplaySetting(e.target.value);
                    }}
                    disabled={loading}
                >
                    {displayOptions.map((o,i) => <MenuItem key={`${i}${o.value}`} value={o.value} >{o.text}</MenuItem>)}
                </Select>
        </FormControl>
    ) : (<span></span>)
}

export default ListViewSelector;