import React, { useState, useEffect } from 'react';
import {Outlet} from 'react-router-dom'
import { ThemeProvider, createTheme} from '@mui/material/styles';
import NavMenu from './NavMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { useQuery, gql } from '@apollo/client';

const drawerWidth = 360;
const theme = createTheme({
	typography: {
		useNextVariants: true,
		fontFamily: "Arial, Helvetica, sans-serif",
	},
	palette: {
		primary: {
			main: "#140a9a"
		},
		secondary: {
			main: "#818386"
		}
	},
	components: {
		MuiTableRow: {
			styleOverrides: {
				root: { //for the body
					height: "100%"
				},
				head: { //for the head
					height: "100%"
				},
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				Paper: {
					backgroundColor: 'black'
				}
			}
		}
	},
	drawerWidth: drawerWidth,
});


// const styles = () => ({
// 	root: {
// 		display: 'flex',
// 		flexGrow: 1,
// 	},
// 	main: {
// 		display: 'block',
// 	},
// 	content: {
// 		flexGrow: 1,
// 		padding: theme.spacing(3),
// 		transition: theme.transitions.create('margin', {
// 			easing: theme.transitions.easing.sharp,
// 			duration: theme.transitions.duration.leavingScreen,
// 		}),
// 		marginLeft: 0,
// 	},
// 	contentShift: {
// 		flexGrow: 1,
// 		padding: theme.spacing(3),
// 		transition: theme.transitions.create('margin', {
// 		easing: theme.transitions.easing.sharp,
// 		duration: theme.transitions.duration.leavingScreen,
// 		}),
// 		marginLeft: drawerWidth - 130,
// 	},
// 	contentPaper: {
// 		//...theme.mixins.gutters(),
// 		padding: theme.spacing(2),
// 		[theme.breakpoints.up('sm')]: {
// 		paddingLeft: theme.spacing(3),
// 		paddingRight: theme.spacing(3),
// 		},
// 		marginTop: 50,
// 	},
// 	grow: {
// 		flexGrow: 1,
// 	},
// 	menuButton: {
// 		marginLeft: -12,
// 		marginRight: 20,
// 	},
// 	appBar: {
// 		transition: theme.transitions.create(['margin', 'width'], {
// 		  easing: theme.transitions.easing.sharp,
// 		  duration: theme.transitions.duration.leavingScreen,
// 		}),
// 	},
// 	cogContainer: {
// 		display: 'flex',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 		flexDirection: 'row',
// 		flexWrap: 'wrap',
// 	}
// });

const Layout = () => {
    const[isOpen, setOpen] = useState(false);
	const handleSetOpen = (open) => setOpen(open);
	const [userAccess, setUserAccess] = useState();

	const dispatch = useDispatch();

	const currentUser = useSelector(state => state.auth);

	const [currentUserWithPermissions, setCurrentUserWithPermissions] = useState(null);

	const [userPreferences, setUserPreferences] = useState([]);

	const GET_USER = gql`
		query Users($userId: String){
			user(id: $userId){
				userAttributes{
					value
					attribute{
						attributeName
					}
				}
			}
		}
	`;

	const GET_USER_PREFERENCES = gql`
		query UserPreference($id: Int, $preferenceId: Int) {
			userPreference(id: $id, preferenceId: $preferenceId) {
				preferenceId,
				value,
				preferenceType {
					name
				}
			}
		}
	`;

	const[gotUser, setGotUser] = useState(false);

	const {data, loading, error} = useQuery(GET_USER, {variables: {userId: currentUser.jwtIdToken.account.localAccountId}});
	const userPreferenceQuery = useQuery(GET_USER_PREFERENCES);

	useEffect(() => {
		if(data && !gotUser) {
			if(data && data.user && data.user[0]) {
				setGotUser(true);
				dispatch({type: "CURRENT_USER", payload: data.user[0]});
				setCurrentUserWithPermissions(data.user[0]);
				if(data.user[0].userAttributes.find(a => a.attribute.attributeName === "Access State").value){
					setUserAccess(true);
				}
			}
		}

	}, [data]);

	useEffect(() => {
		console.log("error getting user", error);
		console.log(JSON.stringify(error))
	}, [error]);

	useEffect(() => {
		if(userPreferenceQuery && userPreferenceQuery.data && userPreferenceQuery.data.userPreference) {
			setUserPreferences(userPreferenceQuery.data.userPreference);
		}
	}, [userPreferenceQuery]);

	useEffect(() => {

	}, [userPreferences])

	const navMenuIsOpen = () => {
		if(userPreferences.find(p => p.preferenceType.name.toUpperCase() === 'KEEP MENU PINNED') && userPreferences.find(p => p.preferenceType.name.toUpperCase() === 'KEEP MENU PINNED').value === true){
			return true;
		}
		else {
			return isOpen;
		}
	}

    return(
		<ThemeProvider theme={theme}>
			<span>
					<NavMenu isOpen={navMenuIsOpen()} handleSetOpen={handleSetOpen} hasAccess={userAccess} currentUser={currentUserWithPermissions} _userPreferences={userPreferences} _setUserPreferences={setUserPreferences}/>
					{(data && userAccess) &&
						<Box
							sx={[
									{
										display: 'block',
										flexGrow: 1,
										p: 3,
										transition: theme => theme.transitions.create('margin', {
											easing: theme.transitions.easing.sharp,
											duration: theme.transitions.duration.leavingScreen,
										}),
										ml: 0,
									},
									navMenuIsOpen() && {
										flexGrow: 1,
										p: 3,
										transition: theme => theme.transitions.create('margin', {
										easing: theme.transitions.easing.sharp,
										duration: theme.transitions.duration.leavingScreen,
										}),
										ml: drawerWidth - 130 + 'px',
									}
								]}
						>
							<Box sx={{mt: 7.5, mx:0}}>
								<Outlet/>
							</Box>
						</Box>
					}
					{error &&
						<Box style={{marginTop: "60px", marginLeft: "0", marginRight: "0"}}>
							<Typography 
								variant="body1" 
								sx={{
									display: 'block',
									flexGrow: 1,
									p: 3,
									transition: theme => theme.transitions.create('margin', {
										easing: theme.transitions.easing.sharp,
										duration: theme.transitions.duration.leavingScreen,
									}),
									ml: 0,
									}}>Error loading. Please try again in a few minutes.</Typography>
						</Box>
					}
					{(loading) &&
						<div style={{marginTop: '80px', paddingLeft: '50px', paddingRight: '50px'}}>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row',
								flexWrap: 'wrap'
							}}>
								<div className="cogs" style={{breakAfter: 'always'}}>
									<i className="fas fa-cog fa-4x fa-spin" data-fa-transform="down-5  right-5"></i>
									<i className="fas fa-cog fa-3x fa-spin" data-fa-transform="down-17 right-3" style={{animationDirection: 'reverse'}}></i>
									<i className="fas fa-cog fa-5x fa-spin" data-fa-transform="left-7" style={{animationDirection: 'reverse'}}></i>
								</div>
								<div style={{width: '100%'}}></div>
								<div style={{breakAfter: 'always', marginTop: '50px'}}>
									Hang tight. Be right with ya'!
								</div>
							</div>
						</div>
					}
				</span>
        </ThemeProvider>
    )
};

export default Layout;