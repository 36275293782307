
import { CircularProgress } from "@mui/material";
import { green } from '@mui/material/colors';


const CustomCircularProgress = () => {

    return (
        <CircularProgress 
            size={24} 
            style={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
            }} 
        />
    )
}

export default CustomCircularProgress;