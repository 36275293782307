import { Avatar, Grid, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Email } from "@mui/icons-material";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       display: 'flex',
//       '& > *': {
//         margin: theme.spacing(1),
//       },
//     },
//     small: {
//       width: theme.spacing(4),
//       height: theme.spacing(4),
//       fontSize: "1rem",
//     },
//     large: {
//       width: theme.spacing(7),
//       height: theme.spacing(7),
//     },
//   }));

class AvatarProfile {
    constructor(name, title, connectionId, email) {
        this.name = name;
        this.title = title;
        this.connectionId = connectionId;
        this.email = email;
    }
}

export default function AvatarList({users, userProfile}) {
    function createAvatar(avatarProfile) {
        let fullName = avatarProfile.name;
        if (fullName === undefined || fullName === "" || fullName === null) {
            fullName = "U";
        }
        let splitName = fullName.split(" ");
        if (splitName.length === 0) {
            splitName[0] = "U";
        }
        let firstName =splitName[0];
        let lastName = splitName[1];
        let lastInitial = "";
        let firstInitial = "";
        if (lastName !== undefined) {
            lastInitial = lastName[0];
        }
        if (firstName !== undefined) {
            firstInitial = firstName[0];
        }
        let initials = firstInitial + lastInitial;
        if (initials === "") {
            initials = "U";
        }
        let avatar =  <Avatar sx={theme => ({ width: theme.spacing(4), height: theme.spacing(4),fontSize: "1rem"})} title={avatarProfile.title} >{initials}</Avatar>;
        let box = <Box m={0.5} p={0.5}>{avatar}</Box>;
        let gridItem = <Grid item key={avatarProfile.title}>{box}</Grid>;
        return gridItem;
    }

    function convertUserToAvatarProfile(user)
    {
        return new AvatarProfile(user.name, user.name, user.connectionId, user.email);
    }
    return (
        <Grid container direction='row' id='activeUsers'>
            {
                createAvatar(new AvatarProfile(
                    userProfile.name,
                    "You",
                    "not important",
                    "You"
                ))
            }
            {
                users.map((avatar) => {
                    return createAvatar(convertUserToAvatarProfile(avatar));
                })
            }
        </Grid>
    )
} 