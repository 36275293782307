import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Container, Button, CircularProgress, Typography, Paper, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsGridOverlay } from './NoRowsGridOverlay';
import { Link } from 'react-router-dom';
import ListViewSelector from '../components/UiComponents/ListViewSelector'
import { useSelector } from "react-redux";
import { gridPageSizes } from '../constants';


export const Requests = () => {
    function getStatus(value, row){
        switch (row.statusId) {
            case 1:
                return "Approved";
            case 2:
                return "Rejected";
            case 3:
                return "Approved-No Response";
            case 4:
                return "Awaiting Review";
            case 5:
                return "Approved, No Further Action";
            case 6:
                return "Approved, See Comments or Markup";
            default:
                return "Unknown"; //Null Values in table
        }

    }
    function getRequestStatus(value, row) {
        if(value === true) {
            return "Approved"
        }
        else if(value === false) {
            return "Rejected";
        }
        else {
            if(Date.parse(row.dateRequired) >= new Date()) {
                return "Awaiting Review";
            }
            else {
                return "Approved-No Response"
            }
        }
    }

    function getIsLatest(value, row) {
        if(requests.find(r => r.parentRequestId === row.id)) {
            //if another request has this request as it's parent id, this one isn't the latest
            return false
        }
        else {
            return true;
        }
    }

    const currentUser = useSelector(state => state.auth);

    const columns = [
        { field: 'documentNumber', headerName: 'Document Number', flex: 0.4},
        { field: 'publicationName', headerName: 'Publication Name', flex: 0.4},
        { 
            field: 'requestorUser',
            headerName: 'Originator',
            flex: 0.35,
            valueGetter: (value, row) => {
                return `${row?.requestorUser?.firstName || ""} ${row?.requestorUser?.lastName || ""}`
        }
        },
        { 
            field: 'dateCreated',
            headerName: 'Date Created',
            type: 'date',
            flex: 0.25,
            valueFormatter: (value) => {
                return new Date(value);
            }
        },
        { 
            field: 'dateRequired',
            headerName: 'Date Required',
            flex: 0.25,
            type: 'date',
            valueFormatter: (value) => {
                return new Date(value);
            }
        },
        { field: 'reminderInterval', headerName: 'Reminder Interval', type:'number', flex: 0.2},
        { field: 'attemptCount', headerName: 'Attempt', flex: 0.2, type: 'number', valueGetter: (value, row) => {return value + 1}},
        { field: 'revisionCount', headerName: 'Revision', flex: 0.2, type: 'number'},
        { 
            field: 'approved',
            headerName: 'Approval',
            flex: 0.25,
            valueGetter: getRequestStatus,
            type: 'singleSelect',
            valueOptions: [
                'Approved',
                'Rejected',
                'Awaiting Review',
            ]
        },
        {
            field: 'parentRequestId',
            headerName: 'Latest',
            flex: 0.25,
            valueGetter: getIsLatest,
            type: 'boolean'
        },
        { 
            field: 'statusId',
            headerName: 'Status',
            flex: 0.3,
            valueGetter: getStatus,
            type: 'singleSelect',
            valueOptions: [
                'Approved',
                'Rejected',
                'Awaiting Review',
                'Approved-No Response'
            ]
        },
        {
            field: 'id',
            headerName: ' ',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                //follow parents to use the latest id for link
                let _id = params.value;
                let _parent = requests.find(r => r.parentRequestId === params.row.id)
                while(_parent){
                    _id = _parent.id;
                    _parent = requests.find(r => r.parentRequestId === _parent.id)
                }
                return (
                <Button 
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={"/viewrequest/" + _id}
                >
                    View
                </Button>
        )}}
    ];

    const [sortModel, setSortModel] = useState([
        {
            field: 'dateCreated',
            sort: 'desc'
        }
    ]);

    const [filterModel, setFilterModel] = useState({
        items: []
    })

    const GET_REQUESTS = gql`
        query Requests($displaySetting: String!, $archived: Boolean!) {
            requests(displaySetting: $displaySetting, archived: $archived) {
                id,
                documentNumber,
                publicationName,
                dateCreated,
                dateRequired,
                approved,
                archived,
                parentRequestId,
                requestorUser {
                    firstName,
                    lastName
                },
                attemptCount,
                revisionCount,
                reminderInterval,
                statusId
            }
        }
    `;

    const [archivedRequests, setArchivedRequests] = useState(false);
    const [displaySetting,setDisplaySetting] = useState("user");//options: user, team, team+, all
    const getRequests = useQuery(GET_REQUESTS, {variables: {displaySetting: displaySetting, archived: archivedRequests}});

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
      });

    const[requests, setRequests] = useState([]);

    const onDisplaySettingsChange = (value) => {
        setDisplaySetting(value);
        getRequests.refetch({displaySetting: value, archived: archivedRequests});
    }

    const onArchivedChange = (checked) => {
        setArchivedRequests(checked);
        getRequests.refetch({displaySetting: displaySetting, archived: checked});
    }

    useEffect(() => {
        if(getRequests?.data && getRequests.data.requests && !getRequests.loading) {
            setRequests(getRequests.data.requests);
        } else {
            setRequests([]);
        }
    }, [getRequests.data, getRequests.loading]);

    const getFilteredRequests = useMemo(() => {
        return requests.filter(r => r.archived === archivedRequests)
        //return getRequests.data.requests.filter(r => r.archived === archivedRequests)
    },[requests, archivedRequests]);

    return(
        <Container maxWidth={false} disableGutters={true}>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <Typography variant="h5" style={{flex: 1}}>Requests</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={archivedRequests}
                            onChange={(e) => {onArchivedChange(e.target.checked)}}
                            color="primary"
                            disabled={getRequests.loading}
                        />
                    }
                    label="Archived"
                    style={{marginTop: '15px'}}
                />
                {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={onlyMyRequests}
                            onChange={(e) => {console.log("archived checked: ", e.target.checked); onOnlyMyRequestsChange(e.target.checked)}}
                            color="primary"
                            disabled={getRequests.loading}
                        />
                    }
                    label="Only My Requests"
                    style={{marginTop: '15px'}}
                /> */}
                <ListViewSelector
                    currentUser={currentUser}
                    displaySetting={displaySetting} 
                    setDisplaySetting={onDisplaySettingsChange}
                    loading={getRequests.loading}
                />
            </Grid>

            {(getRequests &&  getRequests.data && getRequests.data.requests && !getRequests.loading) ?
                <Paper>
                    <div style={{ height: 1000, width: '100%' }}>
                        <DataGrid 
                            sx={{"& .MuiDataGrid-columnHeaderTitle": {overflow: "hidden",lineHeight: "1.2em",whiteSpace: "normal"}}}
                            rows={getFilteredRequests} 
                            columns={columns}
                            slots={{
                                noRowsOverlay: NoRowsGridOverlay,
                            }}
                            pageSizeOptions={gridPageSizes}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            filterModel={filterModel}
                            onFilterModelChange={(model) => setFilterModel(model)}
                            //getRowId={(row) => uuidv4()}
                        />
                    </div>
                </Paper>
            :
                <CircularProgress color="primary"/>
            }
        </Container>
    );
}