import React, {useState, useEffect} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import klogo from "../../img/Komatsu_white.png";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { Popover, Box } from '@mui/material';
import preval from 'preval.macro'
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import ListIcon from '@mui/icons-material/List';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import UserPreferences from './UserPreferences';
import RichTooltip from '../UiComponents/RichTooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import { paperClasses } from "@mui/material/Paper";


import {
  IconButton,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  AppBar,
  Typography,
  Collapse,
  Toolbar,
  Drawer,
  CssBaseline
} from '@mui/material';

const drawerWidth = 240;

const NavMenu = ({isOpen, handleSetOpen, currentUser, hasAccess, _userPreferences, _setUserPreferences}) => {
    const [adminMenuOpen, setAdminMenuOpen] = useState(false);

    const [addRequest, setAddRequest] = useState(false);
    const [adminUsers, setAdminUsers] = useState(false);
    const [adminRequests, setAdminRequests] = useState(false);

    const [informationOpen, setInformationOpen] = useState(false);
    const [infoIconAnchor, setInfoIconAnchor] = useState(null);

    const currentUserAuth = useSelector(state => state.auth);

    const [appBarColor, setAppBarColor] = useState("primary");

    const [preferencesOpen, setPreferencesOpen] = useState(false);

    var userPreferencesList = _userPreferences;
    var setUserPreferencesList = _setUserPreferences;

    const navigate = useNavigate();

    //console.log("user preferences nav menu", userPreferencesList);

    useEffect(() => {
      //console.log('current user');
      //console.log(currentUser);
      if(currentUser && currentUser.userAttributes !== undefined) {
        try {
          if(currentUser.userAttributes.find(a => a.attribute.attributeName === "Can Add Request").value === true) {
            setAddRequest(true);
          }
          else {
            setAddRequest(false);
          }
        }
        catch(error) {
          setAddRequest(false);
        }
      }
      try {
        if(currentUser.userAttributes.find(a => a.attribute.attributeName === "Admin Users").value === true) {
          setAdminUsers(true);
        }
        else {
          setAdminUsers(false);
        }
      }
      catch(error) {
        setAdminUsers(false);
      }
      try {
        if(currentUser.userAttributes.find(a => a.attribute.attributeName === "Admin Requests").value === true) {
          setAdminRequests(true);
        }
        else {
          setAdminRequests(false);
        }
      }
      catch(error) {
        setAdminRequests(false);
      }
    }, [currentUser]);

    return(
        <div style={{display:'flex'}}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={[
                    {transition: (theme) => theme.transitions.create(['margin', 'width'], {
                       easing: theme.transitions.easing.sharp,
                       duration: theme.transitions.duration.leavingScreen,
                     })},
                     isOpen && {
                      width: `calc(100% - ${drawerWidth}px)`,
                      ml: drawerWidth,
                      transition: (theme) => theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                      })
                     }
                    ]}
                style={{backgroundColor: appBarColor}}
            >
            <Toolbar>
                {hasAccess &&
                   <IconButton
                      color="inherit"
                      aria-label="open menu"
                      onClick={() => handleSetOpen(true)}
                      edge="start"
                      sx={[
                        {mr:2},
                        isOpen && {display: 'none'}
                      ]}
                      size="large"
                  >
                      <MenuIcon/>
                  </IconButton>
                }
                <img src={klogo} alt="logo" height="70px" style={{cursor: "pointer"}} onClick={() => navigate("/")}/>
                <Typography variant="h5" color="inherit" style={{paddingLeft: "10px", fontSize: '1.25vw'}}>
                    Technical Publications Review
                </Typography>
                {currentUserAuth.jwtIdToken && 
                    <Typography variant="h6" style={{marginLeft: "auto", marginRight: '10px', fontSize: '1vw'}} color="inherit">{currentUserAuth.jwtIdToken.account.name}</Typography>
                }
                {_userPreferences &&
                  <RichTooltip
                    content={<UserPreferences userPreferences={_userPreferences} setUserPreferences={_setUserPreferences}/>}
                    open={preferencesOpen}
                    placement="bottom"
                    onClose={() => setPreferencesOpen(false)}
                  >
                    <IconButton onClick={() => setPreferencesOpen(true)} size="large">
                      <SettingsIcon style={{color: "white"}}/>
                    </IconButton>
                  </RichTooltip>
                }
                <IconButton component="span"  onClick={(event) => {setInfoIconAnchor(event.currentTarget); setInformationOpen(true);}} size="large">
                  <InfoIcon style={{color: "white"}} />
                </IconButton>
                <Popover
                  open={informationOpen}
                  onClose={() => {setInformationOpen(false); setInfoIconAnchor(null);}}
                  anchorEl={infoIconAnchor}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography variant="subtitle1">Build Date: {preval`module.exports = new Date().toLocaleString();`}</Typography>
                </Popover>
            </Toolbar>
            </AppBar>
            {hasAccess &&
              <span>
                <Drawer
                sx={{width: drawerWidth,flexShrink: 0,
                  [`& .${paperClasses.root}`]: {
                    width: drawerWidth
                  }}}
                variant="persistent"
                anchor="left"
                open={isOpen}
            >
            <Box sx={theme => ({
                  display: 'flex',
                  alignItems: 'center',
                  px: 0,
                  py:1,
                  ...theme.mixins.toolbar,
                  justifyContent: 'flex-end'
                  })}>
                {_userPreferences.find(p => p.preferenceType.name.toUpperCase() === 'KEEP MENU PINNED') && !_userPreferences.find(p => p.preferenceType.name.toUpperCase() === 'KEEP MENU PINNED').value &&
                  <IconButton onClick={() => handleSetOpen(false)} size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                }
            </Box>
            <Divider />
            <List>
              <ListItem component={Link} button key={1} to='/tasks' color="primary" onClick={() => handleSetOpen(false)}>
                  <ListItemIcon><AssignmentIcon/></ListItemIcon>
                  <ListItemText>Tasks</ListItemText>
              </ListItem>
            </List>
            {addRequest &&
              <React.Fragment>
                <List>
                  <ListItem component={Link} button key={2} to='/createrequest' color="primary" onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><AddIcon/></ListItemIcon>
                      <ListItemText>Add Request</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem component={Link} button key={3} to='/requests' color="primary" onClick={() => handleSetOpen(false)}>
                        <ListItemIcon><ListIcon/></ListItemIcon>
                        <ListItemText>Requests</ListItemText>
                    </ListItem>
                </List>
              </React.Fragment>
            }
            <List>
                <ListItem component="a" button key={4} href="http://joyglobaltraining.com/DocReview/Document_Review_Process.pdf" target="_blank" color="primary" onClick={() => handleSetOpen(false)}>
                    <ListItemIcon><MenuBookIcon/></ListItemIcon>
                    <ListItemText>Instructions</ListItemText>
                </ListItem>
              </List>
            <List>
                {(adminUsers || adminRequests) &&
                  <ListItem button key={5} color="primary" onClick={() => setAdminMenuOpen(!adminMenuOpen)}>
                    <ListItemIcon><SupervisorAccountIcon/></ListItemIcon>
                    <ListItemText>Administration</ListItemText>
                    {adminMenuOpen ? <ExpandLess/> : <ExpandMore />}
                  </ListItem>
                }
            </List>
            <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
              <List>
                <ListItem component={Link} button key={6} to='/manageusers' color="primary" onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><PermIdentityIcon/></ListItemIcon>
                      <ListItemText>Manage Users</ListItemText>
                  </ListItem>
              </List>
              <List>
                <ListItem component={Link} button key={7} to='/editreviewtypes' color="primary" onClick={() => handleSetOpen(false)}>
                      <ListItemIcon><BuildIcon/></ListItemIcon>
                      <ListItemText>Review Types</ListItemText>
                  </ListItem>
              </List>
            </Collapse>
            </Drawer>
              </span>
            }
        </div>
    );
}

export default NavMenu;