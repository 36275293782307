import { Box } from '@mui/material';

export const DividerWithText = ({ children }) => {
 return (
  <Box sx={{display: "flex",alignItems: "center"}}>
    <Box sx={{borderBottom: "2px solid #140a9a",flexGrow: 1, '& > .divder-span':{px:2,py:0.5,fontWeight:500, fontSize: 15, color: "primary"}}} />
    <span className='divder-span'>{children}</span>
    <Box sx={{borderBottom: "2px solid #140a9a",flexGrow: 1}} />
  </Box>
 );
};