import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Grid, Paper, Box, Button, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ViewSDKClient from './AdobeEmbed/ViewSDK.js';
import { getBaseURL } from '../baseURL.js';
import { useSelector } from "react-redux";
import CustomCircularProgress from './UiComponents/CustomCircularProgress.js';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { gql, useLazyQuery } from '@apollo/client';
import Axios from "axios";
import  FileDownload  from 'js-file-download';
import { ReactComponent as DownloadIcon } from  '../img/downloadIcon.svg';
import { ReactComponent as DragIcon } from '../img/dragIcon.svg';
import { ReactComponent as RefreshIcon } from '../img/refreshIcon.svg'
import { ReactComponent as AttachmentIcon } from '../img/attachmentIcon.svg'
import ClipboardButton from './UiComponents/ClipboardButton.js';
import { toast, ToastContainer } from 'react-toastify';
import ModalButton from './UiComponents/Modal.js';

//import { Prompt } from 'react-router-dom' currently deprecated may be available in future versions 
import Prompt from './UiComponents/Prompt'
import AvatarList from './AdobeEmbed/AvatarList.js';

const LeaveAttemptMessage = "Changes have not been saved. Are you sure you want to leave?";
let _client;

export const PDFEditor = () => {
    const navigate = useNavigate();

    let { requestId, itemId, fileName, taskId } = useParams();
    //TODO: Delete this state reference
    const [ viewSDKClientState, setViewSDKClient ] = useState(null);
    const [currentUsers, setCurrentUsers] = useState([]);
    const [saveStatus, setSaveStatus] = useState("");
    const [isSafeToLeave, setIsSafeToLeave] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("Loading PDF From Server...");
    const [errorMessage, setErrorMessage] = useState("");
    const pdfDiv = useRef(null);
    const pdfDivContainer = useRef(null);

    const GET_ATTACHMENTS = gql`
        query Attachments($id: Int, $requestId: Int, $taskId: Int) {
            attachments(id: $id, requestId: $requestId, taskId: $taskId) {
                id,
                fileName,
                taskId,
                dateUploaded,
                uploadedByUser {
                    firstName,
                    lastName
                },
                annotatedObjectID
            }
        }
    `;

    const [showAttachmentPanel, setShowAttachmentPanel] = useState(false);
    const [attachmentsLoading, setAttachmentsLoading] = useState(false);
    const [fileUploader, setFileUploader] = useState();
    const [attachmentQuery, attachmentQueryResult] = useLazyQuery(GET_ATTACHMENTS);
    const [attachments, setAttachments] = useState([]);
    const [dragAttachment, setDragAttachment] = useState(null)
    const [activePage, setActivePage] = useState({page: -1, focus: {actual: -1, display: -1}})
    const chunkSize = 10240000;
    const maxRetries = 8;

    const [isLoading, setIsLoading] = useState(true);

    const currentUser = useSelector(state => state.auth);

    const authHeader = {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}

    const config = {
        method: "GET",
        headers: authHeader,
        responseType: 'blob'
      };

    const pdfProfile = {
        userProfile: {
            name: currentUser.jwtIdToken.account.name,
            firstName: currentUser.jwtIdToken.account.name.split(" ")[0],
            lastName: currentUser.jwtIdToken.account.name.split(" ")[1],
            email: currentUser.jwtIdToken.account.username,
        }
    }

    function setUsersCallback(users) {
        console.log("PDF Editor: Setting Users in UI");
        console.log(users);
        setCurrentUsers(users);
    }

    function saveStartCallback(){
        console.log("Setting saving status in UI");
        setSaveStatus("Auto-Saving...");
    }

    function saveEndCallback(success){
        if (success) {
            console.log("Setting Save Completed In UI");
            setSaveStatus("Save Completed");
        }
        else {
            console.log("Setting Save Failed In UI");
            setSaveStatus("Save Failed. Will Retry Shortly...");
        }
        setTimeout(() => {
            console.log("Clearing Save Status in UI");
            setSaveStatus("");
        }, 2000);
    }

    function UnloadEventCallback(ev, viewSdkClient, isSafeToLeavelocal) {
        console.log("Unload Event Callback");
        console.log("Safe to Leave?" + isSafeToLeavelocal())
        if (!isSafeToLeavelocal()) {
            ev.preventDefault();
            return ev.returnValue = LeaveAttemptMessage;
        }
        else {
            viewSdkClient.closeCollaborationConnection();
            return null;
        }
    }
    function getisSafeStatus() {
        return isSafeToLeave;
    }
    const RegisterUnloadEvent = (viewSdkClient) => {
        window.addEventListener("beforeunload", (ev) => {UnloadEventCallback(ev, viewSdkClient, getisSafeStatus) } );
    }

    function handleUnknownError(error) {
        console.log("Unknown Error Occurred");
        console.log(error);
        setIsLoading(false);
        let message = error;
        if (error.code) {
            message = error.code;
        }
        if (error.message) {
            message = error.message;
        }
        setErrorMessage("Error Loading PDF: " + message);
    }

    function viewerEventHandler(event) {

        if (_client === null) {
            console.error('_client is null during event handle')
            return;
        };

        switch(event.type){
            case _client.Enum.FilePreviewEvents.PREVIEW_PAGE_MOUSE_ENTER:
                setActivePage(prev => ({...prev, focus: {actual: event.data.pageNumber, display: event.data.pageNumber}}))
                break;
            case _client.Enum.FilePreviewEvents.PREVIEW_PAGE_MOUSE_LEAVE:
                setActivePage(prev => ({...prev, focus: {...prev.focus, actual: -1}}))
                break;
            case _client.Enum.FilePreviewEvents.CURRENT_ACTIVE_PAGE:
                setActivePage(prev => ({...prev, page: event.data.pageNumber}))
                break;
            default:
                console.log(event)
                break;
        }
    }

    useEffect(() => {
        try {
            console.log("PDF Editor: Initializing ViewSDKClient");
            const viewSDKClient = new ViewSDKClient(itemId, pdfProfile);
            viewSDKClient.ready().then(() => {
                /* Invoke file preview */
                console.log("PDF Editor: Ready");
                const filepath = `${getBaseURL()}/filedownload/${itemId}/true`;

                function pdfPromise () {
                    console.log("Generating PDF Fetch Promise")
                    return new Promise((resolve, reject) => {
                        console.log("Fetching PDF");
                        fetch(filepath, config)
                            .then((response) => response.blob() )
                            .then((blob) => {
                                setLoadingMessage("Reading PDF Into Adobe Viewer...");
                                console.log("Fetched PDF Success");
                                resolve(blob.arrayBuffer());
                            }).catch((error) => {
                                console.log("Fetched PDF Error");
                                reject(error);
                            });
                    })
                }

                const viewerConfig = {
                    /* Control the viewer customization. */
                    showAnnotationTools: true,
                    enableAnnotationAPIs: true,
                    includePDFAnnotations: true,
                    showDisabledSaveButton: false,
                    showDownloadPDF: false,
                    enableFormFilling: false,
                    defaultViewMode: "FIT_PAGE",
                    enableFilePreviewEvents: true,
                };

                viewSDKClient.registerCurrentUsersChangedCallback(setUsersCallback);
                viewSDKClient.registerSaveCallbacks(saveStartCallback, saveEndCallback, setIsSafeToLeave);
                viewSDKClient.registerViewerEventHandler(viewerEventHandler)
                console.log("Calling Preview File Using Promise")
                viewSDKClient.previewFileUsingPromise("adobe-dc-view", pdfPromise, fileName, requestId, authHeader, viewerConfig, setIsLoading, setLoadingMessage, handleUnknownError)
                    .catch((error) => {
                        handleUnknownError(error);
                    }
                );
                setViewSDKClient(viewSDKClient);
                _client = viewSDKClient;

                RegisterUnloadEvent(viewSDKClient, function () { return isSafeToLeave });
            });
            return () => {
                RegisterUnloadEvent(viewSDKClient, function () { return isSafeToLeave });
                _client = undefined;
            }
        } catch (error) {
            handleUnknownError(error);
        }
    }, []);

    const onFilesUploading = (args) => {
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.jwtIdToken.idToken}`);
    }
    const onFilesUploadSuccess = () => {
        setAttachmentsLoading(true)
        attachmentQuery({variables: {requestId: parseInt(requestId)}});
    }

    const onFilesUploadError = (args) => {
        console.log('error loading file')
        console.log(args)
    }

    const isReviewerFileDownloading = (fileId) => {
        try{
            if(attachments.find(a => a.id === fileId).loading) {
                return true;
            }
            else {
                return false;
            }
        }
        catch {
            return false;
        }
    }

    const downloadFile = (attachmentId, fileName, withAnnotations) => {
        var attachmentsCopy = [...attachments];

        //Set attachment to downloading so button can reflect that
        attachmentsCopy.find(a => a.id === attachmentId).loading = true;
        setAttachments(attachmentsCopy);

        Axios.get(`${getBaseURL()}/filedownload/${attachmentId}/${withAnnotations}`, config)
        .then((response) => {
            FileDownload(response.data, fileName);

            attachmentsCopy = [...attachments];
            attachmentsCopy.find(a => a.id === attachmentId).loading = false;
            setAttachments(attachmentsCopy);
        })
        .catch((error) => {
            attachmentsCopy = [...attachments];
            attachmentsCopy.find(a => a.id === attachmentId).loading = false;
            setAttachments(attachmentsCopy);
        });
    }

    const buildFileLink = (item) => {
        if (!item)
            return '';

        const href = `${window.location.origin}/download/${item.id}/${item.annotatedObjectID !== null ? 1 : 0}/${encodeURIComponent(item.fileName)}`;
        const message = `Attachment (copy link):\n ${href}`;
        return message;
    }

    const buildFileDetails = (item) => {
        let isHeader = !item;
        if (!item){
            item = {
                fileName: 'File Name',
                uploadedByUser: {
                    firstName: 'Uploaded',
                    lastName: 'By'
                }
            }
        }

        return (
            <div>
                <div>{item.fileName}</div>
                <div style={{fontSize: 'smaller'}}>{item.uploadedByUser?.firstName} {item.uploadedByUser?.lastName} | {isHeader ? 'Date Uploaded' : new Date(item.dateUploaded).toLocaleDateString()}</div>
            </div>
        )
    }

    const LocalStorageAttachmentKey = 'collab_attachment_status'
    const LocalStorageAttachmentValue = 'pending'
    const handleSetDragAttachment = (value) => {
        localStorage.setItem(LocalStorageAttachmentKey, value ? LocalStorageAttachmentValue : '')
        setDragAttachment(value)
    }

    const addAnnotation = (value = "test annotation") => {
        const isoDate = new Date().toISOString();
        const newComment = {
                "@context": [
                    "https://www.w3.org/ns/anno.jsonld",
                    "https://comments.acrobat.com/ns/anno.jsonld"
                ],
                "id": _client.CollabClient.getNewAnnotationID(),
                "type": "Annotation",
                "motivation": "commenting",
                "bodyValue": value,
                "target": {
                    "source": itemId.toString(),
                    "selector": {
                        "node": {
                            "index": activePage.focus.actual - 1
                        },
                        "opacity": 1, //0.4,
                        "subtype": _client.Enum.AnnotationTypes.NOTE,
                        //"boundingBox" : [Xmin:float, Ymin:float, Xmax:float, Ymax:float],
                        "boundingBox": [5,20,22,38],
                        "strokeColor": "#F8D147",
                        "type": "AdobeAnnoSelector"
                    }
                },
                "creator": {
                    "id": _client.userProfile.userProfile.email,
                    "name": _client.userProfile.userProfile.name,
                    "type": "Person"
                },
                "created": isoDate,
                "modified": isoDate
            }

        _client.CollabClient.addNewAnnotations([newComment])
        .then(x => {
            toast.success(`Attachment Link Added`, {
                position: "top-right",
            });
            handleSetDragAttachment(null)
        })
        .catch(error => {
            toast.error(`Error adding Attachment Link`, {
                position: "top-right",
            });
            handleSetDragAttachment(null)
            console.error(error)
        })
    }

    useEffect(() => {
        if(showAttachmentPanel) {
            setAttachmentsLoading(true)
            attachmentQuery({variables: {requestId: parseInt(requestId)}});
        }

    },[showAttachmentPanel]);

    useEffect(() => {
        if(attachmentQueryResult && attachmentQueryResult.data && attachmentQueryResult.data.attachments) {
            var filteredAttachments = attachmentQueryResult.data.attachments.filter(a => a.id != itemId)
            setAttachments(filteredAttachments);
            setAttachmentsLoading(false)
        }
    }, [attachmentQueryResult]);

    const attachmentTimeoutHandler = () => {
        if (dragAttachment && localStorage.getItem(LocalStorageAttachmentKey) === LocalStorageAttachmentValue) {
            handleSetDragAttachment(null)
            console.log('clear drag attachment, timeout');
            toast.warning(`Drag Action Cancelled`, {
                position: "top-right",
            });
        }
    }

    const clearDragAttachment = async () => {
        setTimeout(attachmentTimeoutHandler, 1500)
    }

    const attachmentTimeout = 1200; //ms

    useEffect(() => {
        //console.log('trigger activePage: ', activePage.focus.actual)

        if (!dragAttachment) return;

        if (activePage.focus.actual < 1) {
            console.log('set timeout to clear drag attachment')
            clearDragAttachment()
            return
        }

        console.log('trigger drop attachment effect')
        console.log(dragAttachment)

        //check timestamp to catch instances when the drop happened outside of a page
        const dropAge = Date.now() - dragAttachment.timestamp
        if (dropAge > attachmentTimeout) {
            console.log('clear drag attachment, age: ', dropAge)
            handleSetDragAttachment(null)
            return;
        }

        clearTimeout(attachmentTimeoutHandler)
        console.log('add attachment to pdf, age: ', dropAge)
        const link = buildFileLink(dragAttachment.attachment)
        addAnnotation(link)

    }, [activePage.focus, dragAttachment])

    return (
    <div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", justifyContent: "space-between", gap: '1em'}}>
                <Button
                        variant='contained'
                        color='primary'
                        onClick={() => { navigate(-1)}}
                        style={{display: "flex", marginBottom: "10px"}}
                    >
                        Return
                </Button>
                <ModalButton
                    buttonText="Technical Issues?"
                    modalContent={
                        <div>
                            <h3>Technical Issues?</h3>
                            <h5>1. Disable Ad-Blockers</h5>
                            <div>The editor was created using a library provided by Adobe. Some of the content on this page is imported from Adobe domains and ad-blockers may block these connections causing unpredictable behavior.</div>
                            <h5>2. Try a different browser</h5>
                            <div>This tool was developed primarily using Chrome, so most Chromium based browsers should work. Some have experienced annotation syncing issues on other browsers.</div>
                        </div>
                    }
                ></ModalButton>
            </div>
            <div style={{display: "flex", flexShrink: 0, justifyContent: "space-between", gap: '1em'}}>
                {(showAttachmentPanel && !attachmentsLoading) && <RefreshIcon 
                    className='svg-button'
                    title='Refresh Attachments' 
                    onClick={() => {
                        onFilesUploadSuccess()
                    }} 
                    style={{height: '2em'}} />}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => { setShowAttachmentPanel(prev => !prev)}}
                    style={{display: !isLoading && errorMessage === "" ? "flex" : "none", marginBottom: "10px", flexShrink: 0}}
                >
                    Attachments
                </Button>
            </div>
        </div>
        <div style={{display: 'flex'}}>
            <section style={{flexGrow: '2', position: 'relative'}}>
                <Paper sx={{ display: 'block', position: 'relative' }}>
                    <Prompt when={!isSafeToLeave} message={LeaveAttemptMessage} onExit={function () { _client.closeCollaborationConnection(); }}/>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <AvatarList users={currentUsers} userProfile={pdfProfile.userProfile}/>
                        </Grid>
                        <Grid item ml={"11%"}>
                            <Box p={0.5} m={0.5}>
                                {saveStatus}
                            </Box>
                        </Grid>
                        <Grid item style={{display: 'flex', gap: '.5em'}}>
                            <Box p={0.5} m={0.5} visibility={"hidden"}>
                                {isSafeToLeave ? "Changes Saved" : "Unsaved Changes"}
                            </Box>
                            {(dragAttachment) && <Box p={0.5} m={0.5}>
                                    <AttachmentIcon 
                                        title='Attachment pending' 
                                        onClick={() => {}} 
                                        style={{height: '1em', color: 'lightgray'}} />
                                </Box>}
                            <Box p={0.5} m={0.5} style={{color: 'grey', fontSize: 'small', display: 'none'}}>
                                Page: {activePage.focus.actual > 0 ? activePage.focus.actual : '_'}/{activePage.page > 0 ? activePage.page : '_'}
                            </Box>
                        </Grid>
                    </Grid>
                    <div style={{display: isLoading && errorMessage === "" ? "flex" : "none" , top:"0px", left: "0px", width: "100%", height: "83vh", background: "rgba(217,217,217,1)", justifyContent: "center", alignItems: "center", zIndex: "500", position: "absolute", borderRadius: "5px"}}>
                        <div>
                            <CustomCircularProgress />
                        </div>
                        <h4 style={{paddingTop: "45px"}}>{loadingMessage}</h4>
                    </div>
                    <div style={{display: !isLoading && errorMessage !== "" ? "flex" : "none" , top:"0px", left: "0px", width: "100%", height: "83vh", background: "rgba(217,217,217,1)", justifyContent: "center", alignItems: "center", flexDirection: "column", position: "absolute", borderRadius: "5px"}}>
                        <div>
                            An Error Occurred
                        </div>
                        <h4 style={{paddingTop: "20px"}}>{errorMessage}</h4>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => { window.location.reload() }}
                            style={{display: "flex"}}
                        >
                                Try Again
                        </Button>
                    </div>
                    <div style={{ position: 'relative'}}>
                        <div id="adobe-dc-view" style={{height: "77vh"}} ref={pdfDiv}></div>
                        <div className='drop-container' 
                            ref={pdfDivContainer} 
                            style={{display: 'none', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 10000, backgroundColor: 'rgba(255,255,255,0.05)' }}
                            onDragOver={(event) => {
                                event.preventDefault()
                            }}
                            onDrop={(event) => {
                                const item = JSON.parse(event.dataTransfer.getData('text/plain'));
                                handleSetDragAttachment({timestamp: Date.now() , attachment: item})
                            }}
                        ></div>
                    </div>
                </Paper>
            </section>
            {showAttachmentPanel && 
                <section style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        maxWidth: '400px',
                        padding: '1px 1px 1px 1em',
                        maxHeight: isLoading && errorMessage === ""  ? " 10vh" : "calc(77vh + 50px)"
                    }
                    }>
                <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}}>
                    <Typography variant="h6">Attached Files</Typography>
                    <Table width={'100%'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{buildFileDetails()}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <div style={{maxHeight: "35vh", overflowY: "auto"}}>
                    <Table width={'100%'}>
                        <TableBody>
                        {attachmentsLoading ? (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <div style={{display: 'flex', justifyContent: 'flex-start', gap: '1em',}}>
                                        <div style={{position: 'relative', width: '2em', height: '2em'}}><CustomCircularProgress/></div>
                                        <span>Loading Attachments ... </span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : attachments.length > 0 ? attachments.map((item, index) => {
                                return(
                                    <TableRow key={index}>
                                        <TableCell>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{cursor: 'grab'}}
                                                    draggable={true}
                                                    onDragStart={(event) => {
                                                        handleSetDragAttachment(null)
                                                        event.dataTransfer.setDragImage(event.target.parentElement,10,10)
                                                        event.dataTransfer.setData('text/plain', JSON.stringify(item))
                                                        pdfDivContainer.current.style.display = 'block'
                                                        }}
                                                    onDragEnd={(event) => {
                                                        pdfDivContainer.current.style.display = 'none'
                                                        }}>
                                                    <DragIcon style={{color: 'grey', height: '2em'}}/>
                                                </div>
                                                {buildFileDetails(item)}
                                            </div>

                                        </TableCell>
                                        <TableCell>
                                            <div style={{display: 'flex', gap: '1em'}}>
                                                {(isReviewerFileDownloading(item.id)) ? (
                                                    <div style={{position: 'relative', height: '2em', width: '2em'}}>
                                                        <CustomCircularProgress />
                                                    </div>
                                                    ) : <DownloadIcon 
                                                        className='svg-button'
                                                        title='download' 
                                                        onClick={() => {downloadFile(item.id, item.fileName, item.annotatedObjectID !== null)}} 
                                                        style={{height: '2em'}}/>}

                                                </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        No Attachments
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        </TableBody>
                    </Table>
                    </div>
                </Paper>
                <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m:1}}} style={{marginTop: '10px',flexShrink: 0}}>
                    <Typography variant="h6">Upload Attachments</Typography>
                    <span>(*max file upload size is 60MB.)</span>
                    <UploaderComponent 
                        autoUpload={false}
                        maxFileSize={62914560}//60MB
                        type = 'file'
                        ref={fileUpload => setFileUploader(fileUpload)}
                        chunkUploading={onFilesUploading}
                        style={{backgroundColor: "blue"}}
                        asyncSettings = {{chunkSize: chunkSize, retryCount: maxRetries, saveUrl: `${getBaseURL()}/fileupload/${requestId}` + ((taskId && taskId !== '0' && taskId > 0) ? `/${taskId}` : '')}}
                        uploading={onFilesUploading}
                        success={onFilesUploadSuccess}
                        failure={onFilesUploadError}
                        id='fileUpload'
                    />
                    <Typography style={{marginTop: '10px', fontSize: 'small'}} variant="subtitle1">Files are uploaded when "File uploaded successfully" is shown after clicking upload. 100% means the files are still uploading.</Typography>
                </Paper>

            </section>}
        </div>
        <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
        />
    </div>
    )
}