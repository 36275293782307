import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBaseURL } from '../baseURL.js';
import { useSelector } from "react-redux";
import CustomCircularProgress from './UiComponents/CustomCircularProgress.js';
import Axios from "axios";
import  FileDownload  from 'js-file-download';

export const DownloadfilePage = () => {

    useEffect(() => {
        //ensure that download redirect is cleared when visiting download page
        localStorage.setItem('download_redirectUri', '')
    }, [])

    let { itemId, fileName, withAnnotation } = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true)

    const currentUser = useSelector(state => state.auth);
    const authHeader = {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
    const config = {
        method: "GET",
        headers: authHeader,
        responseType: 'blob'
      };

    const downloadFile = (attachmentId, fileName, withAnnotations) => {

        Axios.get(`${getBaseURL()}/filedownload/${attachmentId}/${withAnnotations}`, config)
        .then((response) => {
            FileDownload(response.data, fileName);
            setIsLoading(false)
        })
        .catch((error) => {
            console.error(error)
        });
    }

    useEffect(() => {
        try  {
            downloadFile(itemId, decodeURIComponent(fileName), withAnnotation == 1)
        } catch (e) {
            setErrorMessage(e.message)
        }
    },[]);

    return isLoading ? (
        <div style={{display: 'flex', justifyContent: 'flex-start', gap: '1em',}}>
            <div style={{position: 'relative', width: '2em', height: '2em'}}><CustomCircularProgress/></div>
            <span>Loading</span></div>
    ) : (
        <div>{errorMessage ? errorMessage : 'File Downloaded to your browsers download folder. You can close this window.'}</div>
    )
}