import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import './material.css';
import { PublicClientApplication } from "@azure/msal-browser";
import store from "./store";
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

if (window.location.href?.toLowerCase().includes(`${window.location.origin}/download/`)){
  //link is intended for file download, store to override redirect url
  //  note: setting the redirect link within the msalInstance does not work due to settings within the MS auth client

  //msalInstance.controller.config.auth.redirectUri = window.location.href.replace(window.location.origin,"")
  localStorage.setItem('download_redirectUri', window.location.href.replace(window.location.origin,""))
}

await msalInstance.initialize();

const logAuth = false; //additional setting to log auth in authReducer.js and authConfig.js

msalInstance.addEventCallback((message) => {
  if (logAuth) console.log("event", message.eventType);
  store.dispatch({type: message.eventType, payload: message.payload});
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(
  <React.StrictMode>
    <App logAuth={logAuth} pca={msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
