import React, { useState, useEffect } from 'react';
import { useLazyQuery, gql, useMutation, useQuery } from '@apollo/client';
import { Container, Button, CircularProgress, Typography, Paper, Checkbox, FormControlLabel } from "@mui/material";
import CustomCircularProgress from './UiComponents/CustomCircularProgress'
import { PeoplePicker } from './PeoplePicker';
import { toast, ToastContainer } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsGridOverlay } from './NoRowsGridOverlay';
import { gridPageSizes } from '../constants';

export const ManageUsers = () => {

    const checkPermission = (permissionName, currentRow) => {
        if(currentRow.userAttributes.find(a => a.attribute.attributeName === permissionName)) {
            return currentRow.userAttributes.find(a => a.attribute.attributeName === permissionName).value;
        }
        else {
            return false;
        }
    }

    const columns = [
        { field: 'firstName', headerName: 'First Name', flex: 0.4 },
        { field: 'lastName', headerName: 'Last Name', flex: 0.4 },
        { field: 'email', headerName: 'Email', flex: 0.4 },
        { 
            field: 'canAddReviews', 
            headerName: 'Can Add Reviews',
            flex: 0.4,
            renderCell: (params) => {
                return(
                <Checkbox
                    color="primary"
                    checked={checkPermission('Can Add Request', params.row)}
                />
            )}
        },
        { 
            field: 'adminUsers', 
            headerName: 'User Admin',
            flex: 0.4,
            renderCell: (params) => {
                return(
                <Checkbox
                    color="primary"
                    checked={checkPermission('Admin Users', params.row)}
                />
            )}
        },
        { 
            field: 'manager', 
            headerName: 'Manager',
            flex: 0.4,
            renderCell: (params) => {
                return(
                <Checkbox
                    color="primary"
                    disabled={true}
                    checked={checkPermission('Manager', params.row)}
                />
            )}
        }
    ];

    const ADD_USER_MUTATION = gql`
        mutation AddUser($guid: String!, $canCreateReviews: Boolean!, $adminUsers: Boolean!) {
            addUser(guid: $guid, canCreateReviews: $canCreateReviews, adminUsers: $adminUsers) {
                email
            }
        }
    `;

    const UPDATE_USER_ATTRIBUTE_MUTATION = gql`
        mutation UpdateUserAttribute($userGuid: String!, $attributeName: String!, $attributeValue: Boolean!) {
            updateUserAttribute(userGuid: $userGuid, attributeName: $attributeName, attributeValue: $attributeValue) {
                userAttributes{
					value
					attribute{
						attributeName
					}
				}
            }
        }
    `;

    const GET_USER = gql`
		query Users($userId: String){
			user(id: $userId){
                guid,
                firstName,
                lastName,
                email,
				userAttributes{
					value
					attribute{
						attributeName
					}
				}
			}
		}
	`;

    const [sortModel, setSortModel] = useState([
        {
            field: 'lastName',
            sort: 'desc'
        }
    ]);

    const [getUser, getUserResult] = useLazyQuery(GET_USER);
    const [addUser, addUserResult] = useMutation(ADD_USER_MUTATION);
    const [updateUserAttributeMutation, updateUserAttributeResult] = useMutation(UPDATE_USER_ATTRIBUTE_MUTATION);
    const getAllUsersQuery = useQuery(GET_USER);


    const [existingUser, setExistingUser] = useState();
    const [externalUser, setExternalUser] = useState();
    const [canCreateReviews, setCanCreateReviews] = useState(true);
    const [adminUsers, setAdminUsers] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 100,
        page: 0,
      });

    const onAddUser = () => {
        addUser({variables: {guid: externalUser.guid, canCreateReviews: canCreateReviews, adminUsers: adminUsers}})
        .then(x => {
            toast.success(`User "${x.data.addUser.email}" added successfully`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            setExternalUser();
            setCanCreateReviews(true);
        })
        .catch(x => {
            toast.error(`A problem occurred adding user`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        });
    }

    useEffect(() => {
        if(existingUser && existingUser.guid) {
            getUser({variables: {userId: existingUser.guid}});
        }
    }, [existingUser]);

    useEffect(() => {
        if(getAllUsersQuery.data && getAllUsersQuery.data.user) {
            setAllUsers(getAllUsersQuery.data.user);
        }
    }, [getAllUsersQuery]);

    const handleSortChange = (model) => {
        /* if statement to prevent the infinite loop by confirming model is 
         different than the current sortModel state */
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
        }
    };

    const userHasPermission = (permissionName) => {
        if(getUserResult.data && getUserResult.data.user[0] && getUserResult.data.user[0].userAttributes) {
            try {
                getUserResult.data.user[0].userAttributes.find(a => a.attribute.attributeName === permissionName);

                return getUserResult.data.user[0].userAttributes.find(a => a.attribute.attributeName === permissionName).value;
            }
            catch {
                return false;
            }
        }
    }

    const onUpdateUserAttribute = (attributeName, attributeValue) => {
        updateUserAttributeMutation({variables: {userGuid: existingUser.guid, attributeName: attributeName, attributeValue: attributeValue}})
        .then(x => {
            toast.success(`Permission ${attributeName} has been set to ${attributeValue}`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });

            getUserResult.refetch();
        })
        .catch(x => {
            toast.error(`A problem occurred updating permission`, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false
            });
        });
    }

    return (
        <Container>
            <Typography variant="h5">Manage Users</Typography>
            <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m: 1}}}>
                <PeoplePicker
                    _helperText=""
                    _peopleValue={existingUser}
                    _setPeopleValue={setExistingUser}
                    _label="User"
                    _isMultiple={false}
                    _required={false}
                    _internalUsers={true}
                />
                {(getUserResult.data && getUserResult.data.user[0]) &&
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={userHasPermission("Access State")}
                                    onChange={(e) => {onUpdateUserAttribute("Access State", !userHasPermission("Access State"))}}
                                    color="primary"
                                />
                            }
                            label="Has Access"
                            style={{marginTop: '15px'}}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={userHasPermission("Can Add Request")}
                                    onChange={(e) => {onUpdateUserAttribute("Can Add Request", !userHasPermission("Can Add Request"))}}
                                    color="primary"
                                />
                            }
                            label="Can Add Request"
                            style={{marginTop: '15px'}}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={userHasPermission("Admin Users")}
                                    onChange={(e) => {onUpdateUserAttribute("Admin Users", !userHasPermission("Admin Users"))}}
                                    color="primary"
                                />
                            }
                            label="Admin Users"
                            style={{marginTop: '15px'}}
                        />
                    </React.Fragment>
                }
            </Paper>
            <Typography variant="h5">Add Admin User</Typography>
            <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m: 1}}}>
                <PeoplePicker
                    _helperText=""
                    _peopleValue={externalUser}
                    _setPeopleValue={setExternalUser}
                    _label="User"
                    _isMultiple={false}
                    _required={false}
                    _internalUsers={false}
                />
                {externalUser &&
                    <React.Fragment>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={canCreateReviews}
                                    onChange={(e) => {setCanCreateReviews(e.target.checked)}}
                                    color="primary"
                                />
                            }
                            label="Can Add Request"
                            style={{marginTop: '15px'}}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={adminUsers}
                                    onChange={(e) => {setAdminUsers(e.target.checked)}}
                                    color="primary"
                                />
                            }
                            label="Admin Users"
                            style={{marginTop: '15px'}}
                        />
                        <br />
                        <br />
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {onAddUser();}}
                            disabled={addUserResult.loading}
                        >
                            {addUserResult.loading && <CustomCircularProgress />}
                            Add User
                        </Button>

                    </React.Fragment>
                }
            </Paper>
            <Typography variant="h5">All Users</Typography>
            <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m: 1}}}>
                {getAllUsersQuery.loading ?
                <CircularProgress size={24} />
                :
                <div style={{ height: 1000, width: '100%' }}>
                    <DataGrid
                        rows={allUsers}
                        columns={columns}
                        slots={{
                            noRowsOverlay: NoRowsGridOverlay,
                        }}
                        pageSizeOptions={gridPageSizes}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        getRowId={r => r.guid}
                        sortModel={sortModel}
                        onSortModelChange={(model) => handleSortChange(model)}
                    />
                </div>
                }
            </Paper>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />
        </Container>
    );
}