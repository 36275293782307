import * as signalR from "@microsoft/signalr";
import { getBaseURL } from "../../baseURL";

const CollaborationEndpoint = `${getBaseURL()}/Collaboration`
const ignoreSameUserCheck = false;

const SLOW_RECONNECT_TIME = 1000;

const CollaborationEvents = {
    JOIN_DOCUMENT: "JoinDocument",
    LEAVE_DOCUMENT: "LeaveDocument",
    REQUEST_ANNOATION_HISTORY: "RequestAnnotationHistory",
    REQUEST_SAVE_LOCKOUT_STATUS: "RequestCurrentSaveLockoutStatus",
    REQUEST_ALL_USERS: "RequestAllCurrentUsers",
    RECIEVE_ALL_USERS: "RecieveAllCurrentUsers",
    RECIEVE_USER_JOINED: "RecieveUserJoined",
    RECIEVE_USER_LEFT: "RecieveUserLeft",
    RECIEVE_ANNOTATION_HISTORY: "RecieveAnnotationHistory",
    RECIEVE_ADD_ANNOTATION: "RecieveAddAnnotation",
    RECIEVE_DELETE_ANNOTATION: "RecieveDeleteAnnotation",
    RECIEVE_UPDATE_ANNOTATION: "RecieveUpdateAnnotation",
    RECIEVE_CURRENT_SAVE_LOCKOUT_STATUS: "RecieveCurrentSaveLockoutStatus",
    RECIEVE_OTHER_CLIENT_START_UPLOADING: "RecieveClientStartUploading",
    RECIEVE_OTHER_CLIENT_FINISH_UPLOADING: "RecieveClientFinishUploading",
    RECIEVE_ALL_CLIENT_ANNOTATIONS: "RecieveAllClientAnnotations",

    SERVER_REQUESTING_ALL_ANNOTATIONS: "ServerRequestingAllAnnotations",

    SEND_CLIENT_START_UPLOADING: "SendClientStartUploading",
    SEND_CLIENT_FINISH_UPLOADING: "SendClientFinishUploading",
    SEND_ADD_ANNOTATION: "SendAddAnnotation",
    SEND_DELETE_ANNOTATION: "SendDeleteAnnotation",
    SEND_UPDATE_ANNOTATION: "SendUpdateAnnotation",    
}

export default class CollaborationClient {
    //signalR variables
    _connection;

    //PDF Collaboration Variables
    _fileId;
    _userProfile;

    //PDF Collaboration Callback Data
    _currentUsers = [];

    //PDF Collaboration Callback Functions
    currentUsersChangedCallback = (currentUsers) => { console.error("Collaboration Client: Empty Callback executed") };
    saveStartCallback = () => { console.log("Empty Callback executed")};
    saveEndCallback = (success) => {console.log("Empty Callback executed") };
    recieveSaveLockoutCallback = (otherClientSaving) => { console.log("Empty Callback executed")};
    recieveAddAnnotationCallback = (annotation) => { console.log("Empty Callback executed")};
    recieveDeleteAnnotationCallback = (annotationId) => {console.log("Empty Callback executed") };
    recieveUpdateAnnotationCallback = (annotation) => {console.log("Empty Callback executed") };
    receiveAnnotationHistoryCallback = (annotations) => { console.log("Empty Callback executed") };
    retrieveAllAnnotations = async () => { console.log("Empty Callback executed"); return null; };




    constructor(fileId, userProfile) {
        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(CollaborationEndpoint, {withCredentials: false}) //will need to add credentials for production
            .withAutomaticReconnect()
            .build();
        let reconnectionStartTime;
        this._connection.onreconnecting((error) => {
            reconnectionStartTime = Date.now();
            if (error) {
                console.log("Reconnecting due to error: " + error);
            }
            this._runIntializationInvocations();
        });
        this._connection.onreconnected(async (error) => {
                const reconnectionEndTime = Date.now();
                const reconnectionDuration = reconnectionEndTime - reconnectionStartTime;
                console.log("Reconnected after " + reconnectionDuration + "ms");
                if (error) {
                    console.log("Reconnected due to error: " + error);
                }
                const slowRecconect = reconnectionDuration > SLOW_RECONNECT_TIME;
                this._runIntializationInvocations(slowRecconect);
            });
        this._fileId = fileId;
        this._userProfile = userProfile;
        this._currentUsers = [];
    }

    async _registerSaveEvents() {
        this._connection.on(CollaborationEvents.RECIEVE_OTHER_CLIENT_START_UPLOADING, (fileId) => {
            this.otherClientSaving = true;
            this.saveStartCallback();
            console.log("Other Client is Saving");
        });

        this._connection.on(CollaborationEvents.RECIEVE_OTHER_CLIENT_FINISH_UPLOADING, (fileId, success) => {
            this.otherClientSaving = false;
            this.saveEndCallback(success);
            if (success)
            {
                this.waitingToSave = false;
                this.setIsSafeToLeave(true);
            }
            console.log("Other Client is Done Saving");
        });

        this._connection.on(CollaborationEvents.RECIEVE_CURRENT_SAVE_LOCKOUT_STATUS, (fileId, otherClientSaving) => {
            this.otherClientSaving = otherClientSaving;
        });

    }

    async _registerAnnotationEvents() {
        this._connection.on(CollaborationEvents.RECIEVE_ANNOTATION_HISTORY, (fileId, annotationsJson) => {
            console.log("Collaboration Client: Received Annotation History")
            let annotations = JSON.parse(annotationsJson);
            console.log(annotations);
            console.log("Received " + annotations.length + " annotations");
            this.receiveAnnotationHistoryCallback(annotations);
        });

        this._connection.on(CollaborationEvents.RECIEVE_ADD_ANNOTATION, async (fileId, annotation) => {
            console.log("Collaboration Client Received Add Annotation")
            let annotationObj = JSON.parse(annotation);
            await this.recieveAddAnnotationCallback(annotationObj);
        });

        this._connection.on(CollaborationEvents.RECIEVE_DELETE_ANNOTATION, async (fileId, annotationId) => {
            console.log("Collaboration Client Received Delete Annotation")
            await this.recieveDeleteAnnotationCallback(annotationId);
        });

        this._connection.on(CollaborationEvents.RECIEVE_UPDATE_ANNOTATION, (fileId, annotation) => {
            console.log("Collaboration Client Received Update Annotation")
            let annotationObj = JSON.parse(annotation);
            this.recieveUpdateAnnotationCallback(annotationObj);
        });

        this._connection.on(CollaborationEvents.SERVER_REQUESTING_ALL_ANNOTATIONS, async (fileId) => {
            console.log("Collaboration Client: Server Requesting All Annotations");
            let currentAnnotations = await this.retrieveAllAnnotations();
            if (currentAnnotations === null || currentAnnotations === undefined) {
                console.log("No Annotations to Send");
                return;
            }
            let attempts = 0;
            while (attempts < 3) {
                try {
                    await this.invokeRecieveAllClientAnnotations(JSON.stringify(currentAnnotations));
                    console.log("Sent Client-side annotations successfully to server");
                    attempts = 0;
                    break;
                } catch (err) {
                    console.log("Error sending annotations: " + err);
                    attempts++;
                }
            }
            if (attempts > 0) {
                console.log("Failed to send annotations after 3 attempts");
            }
        });
    }

    async _registerUserEvents() {
        this._connection.on(CollaborationEvents.RECIEVE_ALL_USERS, (fileId, users) => {
            let parsedUsers = JSON.parse(users);
            //ensure each user in list is unique
            console.log("Collaboration Client: Recieved All Current Users")
            console.log(parsedUsers);
            this._currentUsers = [...new Map(parsedUsers.filter(user => user.email !== this._userProfile.email || ignoreSameUserCheck).map(user => [user.email, user])).values()];
            this.currentUsersChangedCallback(this._currentUsers);
        });

        this._connection.on(CollaborationEvents.RECIEVE_USER_JOINED, (fileId, userObj) => {
            console.log("Collaboration Client: Recieved User Joined")
            let userArray = JSON.parse(userObj);

            console.log(userArray)
            const sameAsCurrentUserCheck = userArray.email !== this._userProfile.email || ignoreSameUserCheck;
            //check that the user is not already in the list
            if (sameAsCurrentUserCheck && !this._currentUsers.some((user) => user.email === userArray.email)) {
                console.log("User with email doesn't exist in list. Adding to users list...")
                this._currentUsers.push(userArray);
                this.currentUsersChangedCallback(this._currentUsers);
            }
        });

        this._connection.on(CollaborationEvents.RECIEVE_USER_LEFT, (fileId, userObj) => {
            console.log("Collaboration Client: Recieved User Left")
            let userArray = JSON.parse(userObj);
            console.log(userArray)
            const startLength = this._currentUsers.length;
            this._currentUsers = this._currentUsers.filter((user) => user.email !== userArray.email);
            if (startLength !== this._currentUsers.length) {
                console.log("Collaboration Client: User with email exists in list. Removing from users list.")
                this.currentUsersChangedCallback(this._currentUsers);
            } else {
                console.log("Collaboration Client: User with email doesn't exist in list. Nothing to remove from users list.")
            }
        });
    }

    async _registerEvents() {
        await this._registerSaveEvents();
        await this._registerAnnotationEvents();
        await this._registerUserEvents();
    }

    async _runIntializationInvocations(slowRecconect = true) {
        console.log("Collaboration Client: Running Initialization Invocations");
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        await this._connection.invoke(CollaborationEvents.JOIN_DOCUMENT, this._fileId, this._userProfile.name, this._userProfile.email);
        await this._connection.invoke(CollaborationEvents.REQUEST_SAVE_LOCKOUT_STATUS, this._fileId);
        this._connection.invoke(CollaborationEvents.REQUEST_ALL_USERS, this._fileId);
        if (slowRecconect) {
            this._connection.invoke(CollaborationEvents.REQUEST_ANNOATION_HISTORY, this._fileId);
        }
    }

    // Returns a bool indicating if whether it is safe to continue saving
    async invokeStartUploading() {
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        return await this._connection.invoke(CollaborationEvents.SEND_CLIENT_START_UPLOADING, this._fileId);
    }

    async invokeFinishUploading(success) {
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        await this._connection.invoke(CollaborationEvents.SEND_CLIENT_FINISH_UPLOADING, this._fileId, success);
    }

    async invokeAddAnnotation(annotationId, annotation) {
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        console.log("Collaboration Client: Invoking Add Annotation")
        this._connection.invoke(CollaborationEvents.SEND_ADD_ANNOTATION, this._fileId, annotationId, annotation);
    }

    async invokeDeleteAnnotation(annotationId, annotation) {
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        console.log("Collaboration Client: Invoking Delete Annotation");
        await this._connection.invoke(CollaborationEvents.SEND_DELETE_ANNOTATION, this._fileId, annotationId, annotation);
    }

    async invokeUpdateAnnotation(annotationId, annotation) {
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        console.log("Collaboration Client: Invoking Update Annotation");
        await this._connection.invoke(CollaborationEvents.SEND_UPDATE_ANNOTATION, this._fileId, annotationId, annotation);
    }

    async invokeRecieveAllClientAnnotations(annotations) {
        if (!this.isConnected()) {
            console.log("Collaboration Client: Not Connected. Cannot Invoke.");
            return;
        }
        console.log("Collaboration Client: Invoking Recieve All Client Annotations");
        await this._connection.invoke(CollaborationEvents.RECIEVE_ALL_CLIENT_ANNOTATIONS, this._fileId, annotations);
    }

    isConnected() {
        if (this._connection === null || this._connection === undefined) {
            console.error("Collaboration Client: Connection is null or undefined");
            return false;
        }
        const isConnectedState = this._connection._connectionState === "Connected";
        if (!isConnectedState) {
            console.error("Collaboration Client Connection State: " + this._connection._connectionState)
        }
        return isConnectedState;
    }



    async _startConnection(maxAttempts, currentAttempt){
        console.log("Collaboration Client: Starting Connection");
        maxAttempts = maxAttempts || 5;
        currentAttempt = currentAttempt || 1;
        if (currentAttempt > maxAttempts) {
            console.log("Collaboration Max attempts to connect reached.");
            return false;
        }
        try {
            await this._connection.start();
            console.log("SignalR Connected.");
            return true;
        } catch (err) {
            console.log(err);
            setTimeout(() => this._startConnection(maxAttempts, currentAttempt + 1), 500);
        }
    }

    async start() {
        let success = await this._startConnection();
        if(success) {
            await this._registerEvents();
            await this._runIntializationInvocations();
        }
    }

    unregisterEvents() {
        console.log("Unregistering Events");
        this._connection.off(CollaborationEvents.RECIEVE_ALL_USERS);
        this._connection.off(CollaborationEvents.RECIEVE_USER_JOINED);
        this._connection.off(CollaborationEvents.RECIEVE_USER_LEFT);
        this._connection.off(CollaborationEvents.RECIEVE_ANNOTATION_HISTORY);
        this._connection.off(CollaborationEvents.RECIEVE_ADD_ANNOTATION);
        this._connection.off(CollaborationEvents.RECIEVE_DELETE_ANNOTATION);
        this._connection.off(CollaborationEvents.RECIEVE_UPDATE_ANNOTATION);
        this._connection.off(CollaborationEvents.RECIEVE_CURRENT_SAVE_LOCKOUT_STATUS);
        this._connection.off(CollaborationEvents.RECIEVE_OTHER_CLIENT_START_UPLOADING);
        this._connection.off(CollaborationEvents.RECIEVE_OTHER_CLIENT_FINISH_UPLOADING);
        this._connection.off(CollaborationEvents.RECIEVE_ALL_CLIENT_ANNOTATIONS);
        this._connection.off(CollaborationEvents.SERVER_REQUESTING_ALL_ANNOTATIONS);
        this._connection.off(CollaborationEvents.SEND_CLIENT_START_UPLOADING);
        this._connection.off(CollaborationEvents.SEND_CLIENT_FINISH_UPLOADING);
        this._connection.off(CollaborationEvents.SEND_ADD_ANNOTATION);
        this._connection.off(CollaborationEvents.SEND_DELETE_ANNOTATION);
        this._connection.off(CollaborationEvents.SEND_UPDATE_ANNOTATION);
    }

    async close() {
        //notify server we are leaving
        //server has a fallback if this function is not called
        //(example, user closes browser forcing unsafe disconnect)
        console.log("Leaving Document in Collaboration Client");
        try {
            console.log("Collaboration Client: Leaving Document");
            await this._connection.invoke(CollaborationEvents.LEAVE_DOCUMENT, this._fileId, this._userProfile.name);
            console.log("Collaboration Client: Leave Document Invoked Successfully")
        } catch (err) {
            console.log("Error leaving document: " + err);
        }
        this.unregisterEvents();
        try {
            await this._connection.stop();
        }
        catch (err) {
            console.log("Error stopping connection: " + err);
        }
    }

}