import { getBaseURL } from "../../baseURL";
import CollaborationClient from "./CollaborationClient";

const DEFAULT_COMMENT_SIZE = 20.0;
const maxChunkSize = 10240000;
const PREVENT_EDITING_OTHER_USERS_ANNOTATIONS = true;

const chunkFile = (array) => {
    const chunks = [];
    let offset = 0;
    
    while (offset < array.length) {
      const chunk = array.slice(offset, offset + maxChunkSize);
      chunks.push(chunk);
      offset += maxChunkSize;
    }
  
    return chunks;
};

const saveOptions = {
    autoSaveFrequency: 23,
    enableFocusPolling: true,
    showSaveButton: true,
    enablePDFAnalytics: false
}

class ViewSDKClient {
    _applingAnnotationHistory = false;
    _historyAnnotationstoApply = 0;
    _historyAnnotationsApplied = 0;
    constructor(fileId, userProfile) {
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
        this.adobeViewer = undefined;
        this.userProfile = undefined;
        this.activePageNumber = -1;
        this.focusPageNumber = -1;
        this.Enum = undefined;
        //this.viewerEventCallback = undefined;

        this.CollabClient = new CollaborationClient(fileId, userProfile.userProfile);
        //TechPub Task Info
        this.fileId = fileId;
        this.userProfile = userProfile;
        this.filename = undefined;
        this.requestId = undefined;
        this.authHeader = undefined;

        this.recievedFromOtherClient = false;
        this.beforeAddState = undefined;

        this.otherClientSaving = false;

        this.saveStartCallback = undefined;
        this.saveEndCallback = undefined;
        this.setIsSafeToLeave = undefined;

        this.waitingToSave = false;

        this.errorHandler = undefined;
    }

    ready() {
        return this.readyPromise;
    }

    registerSaveCallbacks(saveStartCallback, saveEndCallback, setIsSafeToLeave) {
        function embedSaveStartCallback() {
            this.otherClientSaving = true;
            saveStartCallback();
        }

        function embedSaveEndCallback(success) {
            console.log("Executing Save End Logic in Embed API");
            console.log("Was Successful: " + success)
            console.log("Success Type: " + typeof(success));
            this.otherClientSaving = false;
            saveEndCallback(success);
            if (success)
            {
                this.waitingToSave = false;
                setIsSafeToLeave(true);
            }
        }

        function embedRecieveSaveLockoutCallback(otherClientSaving) {
            this.otherClientSaving = otherClientSaving;
        }

        this.CollabClient.saveStartCallback = embedSaveStartCallback.bind(this);
        this.CollabClient.saveEndCallback = embedSaveEndCallback.bind(this);
        this.CollabClient.recieveSaveLockoutCallback = embedRecieveSaveLockoutCallback.bind(this);
        this.setIsSafeToLeave = setIsSafeToLeave;
        this.saveStartCallback = saveStartCallback;
        this.saveEndCallback = saveEndCallback;
    }

    startCollaborationConnection() {
        return new Promise(async (resolve, reject) => {
            await this.CollabClient.start();
            resolve();
        });
    }

    async closeCollaborationConnection() {
        console.log("Recieved Closing Collaboration Connection in ViewSDK");
        if(this.CollabClient.isConnected())
        {
            await this.CollabClient.close();
        }
    }

    registerCurrentUsersChangedCallback(usersCallback) {
        this.CollabClient.currentUsersChangedCallback = usersCallback;
    }

    /**
     * Sets the profile for the active user in the PDF Embed API
     * IMPORTANT: Needs to be set before loading the file in Embed API
     * @param {*} userProfile 
     */
    _registerUserProfile(userProfile) {
        this.userProfile = userProfile;
        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
            function() {
                return new Promise((resolve, reject) => {
                    resolve({
                        code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                        data: userProfile
                    });
                });
            },
        {});
    }

    registerViewerEventHandler(eventCallbackFN) {
        this.viewerEventCallback = eventCallbackFN;
    }

    _registerViewerEventCallback() {
        //make enums accessible
        this.Enum = {...window.AdobeDC.View.Enum};

        const outer = this

        const previewEventOptions = {
            listenOn: [
                //window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_DOCUMENT_CLICK,
                window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_PAGE_MOUSE_ENTER,
                window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_PAGE_MOUSE_LEAVE,
                window.AdobeDC.View.Enum.FilePreviewEvents.CURRENT_ACTIVE_PAGE
            ],
            enableFilePreviewEvents: true
        }

        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            function(event) {
                switch(event.type){
                    case window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_PAGE_MOUSE_ENTER:
                        outer.focusPageNumber = event.data.pageNumber;
                        break;
                    case window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_PAGE_MOUSE_LEAVE:
                        outer.focusPageNumber = -1;
                        break;
                    case window.AdobeDC.View.Enum.FilePreviewEvents.CURRENT_ACTIVE_PAGE:
                        outer.activePageNumber = event.data.pageNumber;
                        break;
                    default:
                        console.log(event)
                        break;
                }

                outer.viewerEventCallback(event)

            }, previewEventOptions
        )
    }

    _getAnnotationManager() {
        if(this.adobeViewer === undefined) {
            console.log("Adobe Viewer is Undefined");
            throw new Error("Cannot get Annotation Manager before the pdf is rendered");
        }
        //TODO: Infrequently the editor gets stuck waiting for the annotation manager
        //All examples show obtaining this through a promise rather than await
        //This is likely the cause of the issue
        return this.adobeViewer.getAnnotationManager();
    }

    _registerCollaborationClientEventCallbacks(setIsLoading) {
        if(this.adobeViewer === undefined) {
            throw new Error("Cannot Register Annotation Event callbacks before the pdf is rendered");
        }
        async function embedRecieveAddAnnotation(annotation) {
            this.recievedFromOtherClient = true;
            console.log("View SDK: Adding Annotation")
            let annotations = [annotation];
            try {
                let annotationManager = await this._getAnnotationManager();
                await annotationManager.addAnnotations(annotations, { silent: true });
            } catch (error) {
                //will fail for fake annotation sometimes
                //will still add real annotation
                console.error("Failed to Add Annotation");
                console.error(error);
                console.error(annotation);
            }
        }

        async function embedRecieveUpdateAnnotation(annotation) {
            this.recievedFromOtherClient = true;
            console.log("View SDK: Updating Annotation")
            const target = annotation.target;
            const selector = target.selector;
            if (selector) {
                if (selector.subtype === "note") {
                    //to ensure note updates are applied correctly
                    //a space needs to be added to the text otherwise adobe will not identify the change
                    annotation.bodyValue = annotation.bodyValue + " ";
                }
            }
            try {
                let annotationManager = await this._getAnnotationManager();
                await annotationManager.updateAnnotation(annotation);
            }
            catch (error) {
                //something bad happened if we are here
                //most likely means one user updated at the same time a user deleted
                //throw new Error("Failed to Update Annotation with id: " + annotation.id);
                console.error("Failed to Update Annotation");
                console.error(error);
                console.error(annotation);
            }
        }

        async function embedRecieveDeleteAnnotation(annotationId) {
            this.recievedFromOtherClient = true;
            console.log("Attempting to Delete Annotation from Adobe Embed");
            try {
                let annotationManager = await this._getAnnotationManager();
                await annotationManager.deleteAnnotations({annotationIds: [annotationId]});
            }
            catch (error) {
                //something wrong happened, but not as bad as failing an update
                //most likely means annotation is already deleted
                //throw new Error("Failed to Delete Annotation with id: " + annotationId);
            }
        }

        async function embedRecieveAnnotationHistory(annotations) {
            this.recievedFromOtherClient = true;
            this._applyingAnnotationHistory = true;
            console.log("Applying " + annotations.length + " annotations to Embed API");
            this._historyAnnotationstoApply = annotations.length;
            this._historyAnnotationsApplied = 0;
            for(let i=0; i<annotations.length; i++){
                let annotation = annotations[i];
                if (annotation.type === "ADD") {
                    await embedRecieveAddAnnotation.bind(this)(JSON.parse(annotation.AnnotationObject));
                } else if (annotation.type === "UPDATE") {
                    await embedRecieveUpdateAnnotation.bind(this)(JSON.parse(annotation.AnnotationObject));
                }
                else if (annotation.type === "DELETE") {
                    await embedRecieveDeleteAnnotation.bind(this)(JSON.parse(annotation.AnnotationObject).id);
                }
                else if (annotation.type === "DELETE-ALL") {
                    this.recievedFromOtherClient = true;
                    console.log("Attempting to Delete All Annotations from Adobe Embed");
                    try {
                        let annotationManager = await this._getAnnotationManager();
                        await annotationManager.deleteAnnotations();
                    }
                    catch (error) {
                        //something wrong happened, but not as bad as failing an update
                        //most likely means annotation is already deleted
                        //throw new Error("Failed to Delete Annotation with id: " + annotationId);
                    }
                }
                else if (annotation.type === "ADD-ALL") {
                    console.log("Attempting to Add All Annotations from Server History");
                    this.recievedFromOtherClient = true;
                    //HACK: Adding Multiple Annotations at once
                    //prevents the viewer from jumping to the new annotation
                    let annotations = [...JSON.parse(annotation.AnnotationObject)];
                    console.log("Adding " + annotations.length + " annotations to Embed API");
                    try {
                        let annotationManager = await this._getAnnotationManager();
                        for (let i = 0; i < annotations.length; i++) {
                            let annotation = annotations[i];
                            annotation = this._checkAnnotationBoundingBox(annotation);
                            embedRecieveAddAnnotation.bind(this)(annotation);
                        }
                    } catch (error) {
                        console.error("Failed to Add Annotation");
                        console.error(annotation);
                        console.error(error);
                    }
                }

            }
            setTimeout(() => {
                //wait for all annotations to be applied
                //zoom the page in slighlty to force render all free text annotations
                //they appear blurry until zoom is changed
                console.log("Finished Waiting for Hisotorical Annotations");
                this._applyingAnnotationHistory = false;
                this.recievedFromOtherClient = false;
                setIsLoading(false);
                //once page is rendered zoom in to force render all free text annotations
                setTimeout(() => {
                    this.adobeViewer.getAPIs().then((api) => {
                        api.getZoomAPIs().setZoomLevel(1.0);
                    });
                }, 200);
            }, 1250);
        }

        async function retrieveAllAnnotations() {
            let annotationManager = await this.adobeViewer.getAnnotationManager()
            let annotations = await annotationManager.getAnnotations();
            return await annotations;
        }

        async function addNewAnnotations(annotations) {
            let annotationManager = await this.adobeViewer.getAnnotationManager()
            let response = await annotationManager.addAnnotations(annotations, { silent: true });
            return await response;
        }

        function getNewAnnotationID() {
            return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }

        this.CollabClient.recieveAddAnnotationCallback = embedRecieveAddAnnotation.bind(this);
        this.CollabClient.recieveUpdateAnnotationCallback = embedRecieveUpdateAnnotation.bind(this);
        this.CollabClient.recieveDeleteAnnotationCallback = embedRecieveDeleteAnnotation.bind(this);
        this.CollabClient.receiveAnnotationHistoryCallback = embedRecieveAnnotationHistory.bind(this);
        this.CollabClient.retrieveAllAnnotations = retrieveAllAnnotations.bind(this);
        this.CollabClient.addNewAnnotations = addNewAnnotations.bind(this);
        this.CollabClient.getNewAnnotationID = getNewAnnotationID.bind(this);
    }
    
    _getCorrectClientId() {
        //these are safe to be public
        //they are bound to the domain
        if(window.location.hostname.includes("localhost")) {
            return "8c0cd670273d451cbc9b351b11d22318";
        }
        else if(window.location.hostname.includes("techpubsreview-dev.mining.komatsu")) {
            //Bound to Adobe account of Tyler Faulkner at Xorbix
            return "5d564fe404bb4a6bb3e4fd53b880c158";
        }
        else if(window.location.hostname.includes("techpubsreview.mining.komatsu")) {
            //Bound to Adobe account of Tyler Faulkner at Xorbix
            return "9bae288fb59a49d0bf27914ef3a29690";
        }
        else {
            console.log("error getting ViewSDK ClientID");
        }
    }

    previewFileUsingPromise(divId, filePromise, filename, requestId, authHeader, viewerConfig, setIsLoading, setLoadingMessage, errorHandler) {
        /* Initialize the AdobeDC View object */
        return new Promise(async (resolve, reject) => {
            try {
                this.adobeDCView = new window.AdobeDC.View({
                    /* Pass your registered client id */
                    clientId: this._getCorrectClientId(),
                    /* Pass the div id in which PDF should be rendered */
                    divId: divId,
                    sendAutoPDFAnalytics: false,
                });
                this._registerUserProfile(this.userProfile);
                this._registerViewerEventCallback();
                this.requestId = requestId;
                this.authHeader = authHeader;
                this.filename = filename;
                this.errorHandler = errorHandler;
                /* Invoke the file preview API on Adobe DC View object */
                this.adobeDCView.previewFile({
                    /* Pass information on how to access the file */
                    content: {
                        /* pass file promise which resolve to arrayBuffer */
                        promise: filePromise()
                    },
                    /* Pass meta data of file */
                    metaData: {
                        /* file name */
                        fileName: this.filename,
                        id: this.fileId
                    }
                }, viewerConfig).then(async (adobeViewer) => {
                    this.adobeViewer = adobeViewer;
                    console.log("ViewSDK: PDF Read And Loaded Successfully");
                    setLoadingMessage("Syncing PDF Annotations...");
                    this._registerSaveApiHandler();
                    this._registerCollaborationClientEventCallbacks(setIsLoading);
                    this._registerAnnotationEventHandlers();
                    this.startCollaborationConnection();
                    resolve();
                }).catch((error) => {
                    console.log("Error reading PDF and loading Adobe Viewer")
                    console.log(error);
                    reject(error);
                    errorHandler(error);
                });
            } catch (error) {
                console.log("Error initializing AdobeDC View Object")
                console.log(error);
                reject(error);
                errorHandler(error);
            }
        });
    }

    _registerSaveApiHandler() {
        /* Define Save API Handler */
        async function saveApiHandler(metaData, content, options) {
            const response = {
                code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                data: {
                    metaData: Object.assign(metaData, {updatedAt: new Date().getTime()})
                },
            };
            //check if client is connected to hub
            //if not connected to hub then skip save
            //avoid sync issues when saving
            //TODO: Add a warning that the user is not connected
            if (!this.CollabClient.isConnected()) {
                return new Promise((resolve, reject) => {
                    console.log("Not Connected to SignalR Hub. Skipping save on this client");
                    resolve(response);
                });
            }
            if (this._applyingAnnotationHistory){
                return new Promise((resolve, reject) => {
                    console.log("Performing initial Load. Applying Annotation History. Skipping save on this client");
                    resolve(response);
                });
            }
            if (!this.waitingToSave){
                return new Promise((resolve, reject) => {
                    console.log("No Changes need to save. Skipping save on this client");
                    resolve(response);
                });
            }
            if (this.otherClientSaving) {
                return new Promise((resolve, reject) => {
                    console.log("Other Client is Saving. Skipping save on this client");
                    resolve(response);
                });
            }
            //Final Safety Check from Server
            let isSafeToSave = await this.CollabClient.invokeStartUploading();
            if (isSafeToSave) {
                console.log("Safe to Save. Starting Save on this client");
                this.saveStartCallback();
                console.log("Save API Handler");
                console.log(metaData, content, options);
                const endpoint = `${getBaseURL()}/filereplace/${this.fileId}`
                const chunks = chunkFile(content);
                let uploadSuccess = false;
                if (chunks.length > 1) {
                    let failCount = 0;
                    for (let i = 0; i < chunks.length || failCount > 5;) {
                        let chunk = chunks[i];
                        //convert chunk to file
                        let fileChunk = new File([chunk], this.filename);
                        const formData = new FormData();
                        formData.append("chunkFile", fileChunk);
                        formData.append("chunk-index", i);
                        formData.append("total-chunk", chunks.length);
                    
                        // Send the chunk to the server
                        try {
                            let result = await fetch(endpoint, {
                                                method: "POST",
                                                body: formData,
                                                headers: this.authHeader
                                            })
                            if (result.ok){
                                i++;
                                failCount = 0;
                                console.log(`Chunk ${i} uploaded successfully.`);
                            } else {
                                console.log(`Error uploading chunk ${i}.`);
                                console.log("Retrying chunk upload.");
                                failCount++;
                            }
                        }
                        catch (e)
                        {
                            console.error("Failed To Post Chunk");
                            console.error(e);
                        }
                    }
                    if (failCount > 0) {
                        console.error("Failed to upload file. Too many failed chunks.");
                    } else {
                        uploadSuccess = true;
                        console.log(`File uploaded successfully.`);
                    }
                }
                else {
                    const formData = new FormData();
                    let fileChunk = new File([chunks[0]], this.filename);

                    formData.append("fileUpload", fileChunk);
                
                    // Send the chunk to the server
                    try {
                        let result = await fetch(endpoint, {
                                            method: "POST",
                                            body: formData,
                                            headers: this.authHeader
                                        })
                        if (result.ok){
                            uploadSuccess = true;
                            console.log(`File uploaded successfully.`);
                        } else {
                            console.error(`Error uploading file. Error Code: ${result.status}. Error Message: ${result.statusText}`);
                        }
                    }
                    catch (e)
                    {
                        console.error("Failed To Post File");
                        console.error(e);
                    }
                }
                this.saveEndCallback(uploadSuccess);
                this.setIsSafeToLeave(uploadSuccess);
                //finished saving no longer waiting
                this.waitingToSave = !uploadSuccess;
                await this.CollabClient.invokeFinishUploading(uploadSuccess);
            }
            else {
                console.log("Server indicated it is Not Safe to Save. Skipping save on this client");
            }
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        };

        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.SAVE_API,
            saveApiHandler.bind(this),
            saveOptions
        );
    }

    _checkAnnotationBoundingBox (annotation, annotationManager = undefined) {
        console.log("Checking Annotation Bounding Box");
        console.log(annotation);
        //copy annotation
        let annotationCopy = JSON.parse(JSON.stringify(annotation));
        if (annotation.motivation === "commenting") {
            const target = annotation.target;
            const selector = target.selector;
            if (selector.subtype === "note") {
                const boundingBox = selector.boundingBox;
                const x1 = boundingBox[0];
                const y1 = boundingBox[1];
                const x2 = boundingBox[2];
                const y2 = boundingBox[3];
                const width = x2 - x1;
                const height = y2 - y1;
                let annotationChanged  = false;
                if (width !== DEFAULT_COMMENT_SIZE) {
                    annotationChanged = true;
                    annotationCopy.target.selector.boundingBox[2] = x1 + DEFAULT_COMMENT_SIZE;
                }
                if (height !== DEFAULT_COMMENT_SIZE) {
                    annotationCopy.target.selector.boundingBox[3] = y1 + DEFAULT_COMMENT_SIZE;
                    annotationChanged = true;
                }
                if (annotationChanged) {
                    console.log("Updating Annotation Bounding Box");
                    console.log(annotationCopy);
                    annotationCopy.bodyValue = annotationCopy.bodyValue + " ";
                    if (annotationManager !== undefined) {
                        annotationManager.updateAnnotation(annotationCopy).then(() => console.log("Updated Bounding Box")).catch((error) => {
                            console.error("Failed to Update Annotation Bounding Box");
                            console.error(error);
                        });
                    }
                }
            }
        }
        return annotationCopy;
    }

    _registerAnnotationEventHandlers() {
        this.adobeViewer.getAnnotationManager().then(annotationManager => {
            async function UpdateOtherClients(event) {
                try {
                    if (event.type === "ANNOTATION_ADDED") {
                        if (!this.recievedFromOtherClient) {
                            await this.CollabClient.invokeAddAnnotation(event.data.id, JSON.stringify(event.data));
                            this._checkAnnotationBoundingBox(event.data, annotationManager);
                        }
                     } else if (event.type === "ANNOTATION_UPDATED") {
                        if (!this.recievedFromOtherClient) {
                            await this.CollabClient.invokeUpdateAnnotation(event.data.id, JSON.stringify(event.data));
                        }
                    }
                    else if (event.type === "ANNOTATION_DELETED") {
                        if (!this.recievedFromOtherClient) {
                            await this.CollabClient.invokeDeleteAnnotation(event.data.id, JSON.stringify(event.data));
                        }
                    }
                    //no changes means only two historical annotations
                    //which would be delete all and add all
                    if(!this._applyingAnnotationHistory || this._historyAnnotationstoApply > 2)
                    {
                        //we have received a change and are now waiting to save the changes
                        this.waitingToSave = true;
                        this.setIsSafeToLeave(false);
                    }
                    if(this.recievedFromOtherClient && !this._applyingAnnotationHistory) {
                        this.recievedFromOtherClient = false;
                    }
                } catch (error) {
                    console.error("Failed to Update Other Clients");
                    console.error(error);
                    this.errorHandler(error);
                }
            }
            annotationManager.registerEventListener(
                UpdateOtherClients.bind(this),
                {
                    listenOn: ["ANNOTATION_ADDED", "ANNOTATION_DELETED", "ANNOTATION_UPDATED"],
                }
            );

            function DisableEditingOtherUsersAnnotations(event){
                if (event.data.creator.name !== this.userProfile.userProfile.name && PREVENT_EDITING_OTHER_USERS_ANNOTATIONS) {
                    annotationManager.unselectAnnotation();
                }
            }
            annotationManager.registerEventListener(
                DisableEditingOtherUsersAnnotations.bind(this),
                {
                    listenOn: ["ANNOTATION_SELECTED", "ANNOTATION_CLICKED"],
                }
            );
        });
    }
}

export default ViewSDKClient;