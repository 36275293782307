import React, { useState, useEffect } from 'react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { 
    Container, 
    TextField, 
    Button, 
    CircularProgress, 
    Typography, 
    Paper, 
    Grid,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import CustomCircularProgress from './UiComponents/CustomCircularProgress'

export const EditReviewTypes = () => {

    const GET_REVIEW_TYPES = gql`
        query ReviewTypes{
            reviewType{
                id,
                name,
                description,
                active
            }
        }
	`;

    const UPSERT_REVIEW_TYPE = gql`
        mutation upsertReviewType($id: Int, $name: String!, $description: String!, $active: Boolean){
            upsertReviewType(id: $id, name: $name, description: $description, active: $active) {
                id
            }
        }
    `;

    const reviewTypeQuery = useQuery(GET_REVIEW_TYPES);

    const [reviewTypes, setReviewTypes] = useState([]);
    const [reviewTypeMutation, reviewTypeMutationResult] = useMutation(UPSERT_REVIEW_TYPE);

    useEffect(() => {
        if(reviewTypeQuery.data && reviewTypeQuery.data.reviewType) {
            setReviewTypes(reviewTypeQuery.data.reviewType);
        }
    }, [reviewTypeQuery]);

    const saveReviewType = (id) => {
        let reviewTypesCopy = [...reviewTypes];
        reviewTypesCopy.find(x => x.id === id).loading = true;
        setReviewTypes(reviewTypesCopy);

        //console.log(reviewTypes);
        reviewTypeMutation({variables: {id: id, name: reviewTypesCopy.find(x => x.id === id).name, description: reviewTypesCopy.find(x => x.id === id).description, active: reviewTypesCopy.find(x => x.id === id).active}})
        .then(() => {
            reviewTypeQuery.refetch();
        });
    }

    const reviewTypeSaving = (id) => {
        try {
            if(reviewTypes.find(x => x.id === id).loading) {
                return true;
            }
            return false;
        }
        catch {
            return false;
        }
    }

    const onNameUpdate = (id, name) => {
        console.log("Name: ", name);
        let reviewTypesCopy = [...reviewTypes];
        reviewTypesCopy.find(x => x.id === id).name = name;
        setReviewTypes(reviewTypesCopy);
    }

    const onDescriptionUpdate = (id, description) => {
        let reviewTypesCopy = [...reviewTypes];
        reviewTypesCopy.find(x => x.id === id).description = description;
        setReviewTypes(reviewTypesCopy);
    }

    const onActiveUpdate = (id, active) => {
        let reviewTypesCopy = [...reviewTypes];
        reviewTypesCopy.find(x => x.id === id).active = active;
        setReviewTypes(reviewTypesCopy);
    }

    return (
        <Container>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <Typography variant="h5" style={{flex: 1}}>Edit Review Types</Typography>
                {/* <Button
                    variant="contained"
                    color="primary"
                >
                    Add Review Type
                </Button> */}
            </Grid>
            {(reviewTypeQuery.data && reviewTypes) ?
                reviewTypeQuery.data.reviewType.map((item, index) => {
                    return(
                        <Paper sx={{px: 2, py: 2, '& .MuiTextField-root': {m: 1}}} style={{marginTop: '10px'}}>
                            <TextField
                                variant="outlined"
                                color="primary"
                                value={item.name}
                                fullWidth
                                onChange={(e) => {onNameUpdate(item.id, e.target.value)}}
                            />
                            <TextField
                                variant="outlined"
                                color="primary"
                                value={item.description}
                                onChange={(e) => {onDescriptionUpdate(item.id, e.target.value)}}
                                fullWidth
                                multiline
                                minRows={4}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={item.active}
                                        onChange={(e) => {onActiveUpdate(item.id, e.target.checked)}}
                                        color="primary"
                                    />
                                }
                                label="Active"
                            />
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={reviewTypeSaving(item.id)}
                                onClick={() => saveReviewType(item.id)}
                            >
                                {reviewTypeSaving(item.id) && <CustomCircularProgress />}
                                Save
                            </Button>
                        </Paper>
                    )})
            :
                <CircularProgress color="primary"/>
            }
        </Container>
    );
}