import React, { useState, useEffect} from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { 
    TextField, 
    CircularProgress, 
    Avatar, 
    Card, 
    CardHeader, 
    Typography, 
    debounce,
    Autocomplete
} from "@mui/material";
import { matchSorter } from 'match-sorter';
import { inspect } from 'util' // or directly


export const PeoplePicker = ({_helperText, _isMultiple, _label, _peopleValue, _setPeopleValue, _required, _internalUsers, _isError, _errorText}) => {
    const [peopleList, setPeopleList] = useState([]);
    const [autocompleteInput, setAutocompleteInput] = useState("");

    const onAutocompleteInputChange = (event, value) => {
        setAutocompleteInput(value);
    }

    const SEARCH_GRAPH_USER = gql`
        query GraphUserSearch($searchText: String, $internalUser: Boolean!){
            peoplePickerUserSearch(searchText: $searchText, internalUser: $internalUser){
                guid,
                text,
                secondaryText,
                key
            }
        }
    `;

    const [runGraphUserQuery, graphUserQuery] = useLazyQuery(SEARCH_GRAPH_USER, {variables: { searchText: autocompleteInput, internalUser: _internalUsers}});

    const runSearch = () => {
        if(autocompleteInput.length >= 3) {
            runGraphUserQuery();
        }
    }

    useEffect(() => {
        let debouncer = setTimeout(() => {
            runSearch();
        }, 1000);
        return () => {
            clearTimeout(debouncer);
        }
    }, [autocompleteInput]);

    useEffect(() => {
        if(!graphUserQuery.loading && graphUserQuery.data){
            if(graphUserQuery.data.peoplePickerUserSearch) {
                //console.log(graphUserQuery.data.peoplePickerUserSearch.concat(_peopleValue))
                setPeopleList(graphUserQuery.data.peoplePickerUserSearch.concat(_peopleValue));
            }
            else {
                //console.log([].concat(_peopleValue))
                setPeopleList([].concat(_peopleValue));
            }
        }
    }, [graphUserQuery.data]);

    useEffect(() => {
        if(_peopleValue !== null) {
            setPeopleList([].concat(_peopleValue));
        }
    }, [_peopleValue]);

    const onTextboxFocusOut = () => {
        if(!_peopleValue) {
            setPeopleList([]);
        }
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: option => option.text + option.secondaryText,
      });

    return(
        <React.Fragment>
            <Autocomplete
                className="customAutoComplete"
                renderOption={(props,option,state, ownerState) => {
                    return (
                     (option !== null && option !== undefined && option !== "" && option.text !== undefined) && 
                    <Card {...props} style={{width: "100%"}}>
                        <CardHeader
                            avatar={<Avatar>{option.text.substring(0, 2)}</Avatar>}
                            title={option?.text || ""}
                            subheader={option?.secondaryText || ""}
                        />
                    </Card>
                )}}
                renderInput={(params) => {
                    //console.log(inspect(params))
                    return (
                     <TextField
                        {...params}
                        label={_label}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill,
                            form: {
                                autocomplete: 'off',
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {graphUserQuery.loading ? <CircularProgress color="inherit" size={20} /> : null} {/* color="primary" */}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>)
                                }}
                        helperText={_helperText}
                        onBlur={() => {
                            onTextboxFocusOut();
                        }}
                        required={_required}
                        error={_isError}
                    />
                )}}
                options={peopleList}
                loading={graphUserQuery.loading}
                onInputChange={onAutocompleteInputChange}
                getOptionLabel={option => {
                    if (option === undefined || option === null) return "";
                    return option.text === undefined ? "" : option.text}}
                multiple={_isMultiple}
                isOptionEqualToValue={(option, value) => {
                    if (option.guid === value.guid) {
                        return true;
                    }
                    return false;
                }}
                value={ _peopleValue }

                //disables autocomplete filter. Server handles filtering
                filterOptions={(x) => x}
                onChange={(e, value) => {_setPeopleValue(value); console.log("value: ", value)}}
            />
            <Typography variant="caption" style={{color: "red"}}>{_errorText}</Typography>
        </React.Fragment>
    );
}