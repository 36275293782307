export const getBaseURL = () => {
    if(window.location.hostname.includes("localhost")) {
        return "https://localhost:5001/api/v1";
    }
    else if(window.location.hostname.includes("techpubsreview-dev.mining.komatsu")) {
        return "https://kmc-techpubsreview-api-dev.azurewebsites.net/api/v1"
    }
    else if(window.location.hostname.includes("techpubsreview.mining.komatsu")) {
        return "https://kmc-techpubsreview-api.azurewebsites.net/api/v1"
    }
    else {
        console.log("error getting baseURL: base url else");
    }
}

export const getGraphqlUrl = () => {
    if(window.location.hostname.includes("localhost")) {
        return "https://localhost:5001/graphql";
    }
    else if(window.location.hostname.includes("techpubsreview-dev.mining.komatsu")) {
        return "https://kmc-techpubsreview-api-dev.azurewebsites.net/graphql"
    }
    else if(window.location.hostname.includes("techpubsreview.mining.komatsu")) {
        return "https://kmc-techpubsreview-api.azurewebsites.net/graphql"
    }
    else {
        console.log("error getting GraphqlUrl: graphql else");
    }
}

