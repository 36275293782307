import React, { useState, useEffect, useRef } from 'react';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import { 
    Container,
    TextField,
    Button,
    CircularProgress,
    Typography,
    Paper,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    FormHelperText,
    Grid
} from "@mui/material";
import { PeoplePicker } from './PeoplePicker';
import CustomCircularProgress from './UiComponents/CustomCircularProgress'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { UploaderComponent } from '@syncfusion/ej2-react-inputs';

import { getBaseURL } from '../baseURL';
import { useSelector } from "react-redux";
import { green } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import '../HideUploadButtonInUploader.css'

export const MakeRequest = () => {

    const currentUser = useSelector(state => state.auth);
    let navigate = useNavigate();

    const addDays = (days) => {
        var result = new Date();
        result.setDate(result.getDate() + days);
        return result;
    }

    let tomorrowDate = addDays(1);
    const chunkSize = 10240000;
    const maxRetries = 8;
    //const [retryCount, setRetryCount] = useState(0);

    const [reviewType, setReviewType] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [publicationName, setPublicationName] = useState("");
    const [reviewDescription, setReviewDescription] = useState("");
    const [origionator, setOriginator] = useState(null);
    const [reviewers, setReviewers] = useState([]);
    //const [customMessage, setCustomMessage] = useState("");
    //const [editor, setEditor] = useState();
    const [reviewDate, setReviewDate] = useState(new Date());
    const [dueDate, setDueDate] = useState(tomorrowDate);
    const [fileUploader, setFileUploader] = useState();
    const uploader = useRef(null);
    const [reminderInterval, setReminderInterval] = useState(1);
    const [persistent, setPersistent] = useState(false);
    const [requestId, setRequestId] = useState();
    const [uploadingNewRequest, setUploadingNewRequest] = useState(false);
    //const [uploadedFiles, setUploadedFiles] = useState(false);
    const [filesUploaded, setFilesUploaded] = useState(false);
    const [filesLoading, setFilesLoading] = useState(false);

    const [errorOccured, setErrorOccured] = useState(false);
    const [errorFiles, setErrorFiles] = useState([]);

    const [fileUploadedCount, setFileUploadedCount] = useState(0);
    const [filesToUploadCount, setFilesToUploadCount] = useState(0);
    const [requestAdded, setRequestAdded] = useState(false);
    const [reviewTypeError, setReviewTypeError] = useState({isError: false, errorText: ""});
    const [documentNumberError, setDocumentNumberError] = useState({isError: false, errorText: ""});
    const [publicationNameError, setPublicationNameError] = useState({isError: false, errorText: ""});
    const [origionatorError, setOrigionatorError] = useState({isError: false, errorText: ""});
    const [reviewersError, setReviewersError] = useState({isError: false, errorText: ""});
    const [reminderIntervalError, setReminderIntervalError] = useState({isError: false, errorText: ""});
    const [revisionCount, setRevisionCount] = useState(0);
    const [attemptCount, setAttemptCount] = useState(1);

    const GET_REVIEW_TYPES = gql`
        query ReviewTypes($active: Boolean) {
            reviewType(active: $active) {
                id,
                name,
                description
            }
        }
	`;

    const GET_REMINDER_INTERVALS = gql`
        query ReminderIntervals($active: Boolean) {
            reminderIntervals(active: $active) {
                id,
                days
            }
        }
    `;

    const GET_REQUEST = gql`
        query Requests($requestId: Int!) {
            requests(requestId: $requestId) {
                id,
                documentNumber,
                dateCreated,
                dateRequired,
                description,
                reviewType,
                publicationName,
                approved,
                reminderInterval,
                attemptCount,
                revisionCount,
                requestorUser {
                    guid,
                    email,
                    firstName,
                    lastName
                }
            }
        }
    `;

    const ADD_REQUEST_MUTATION = gql`
        mutation AddRequest($reviewType: Int!, $documentNumber: String!, $publicationName: String!, $description: String, $origionator: String!, $reviewers: [String!]!, $reviewDate: DateTime!, $dueDate: DateTime!, $reminderInterval: Int!, $persistent: Boolean!, $parentRequestId: Int, $isAttempt: Boolean, $isRevision: Boolean) {
            addRequest(reviewType: $reviewType, documentNumber: $documentNumber, publicationName: $publicationName, description: $description, originator: $origionator, reviewers: $reviewers, reviewDate: $reviewDate, dueDate: $dueDate, reminderInterval: $reminderInterval, persistent: $persistent, parentRequestId: $parentRequestId, isAttempt: $isAttempt, isRevision: $isRevision) {
                id
              }
        }
    `;

	const reviewTypeQuery = useQuery(GET_REVIEW_TYPES, {variables: {active: true}});
    const reminderIntervals = useQuery(GET_REMINDER_INTERVALS);

    const [requestQuery, requestQueryResult] = useLazyQuery(GET_REQUEST);

    const [addRequest, addRequestResult] = useMutation(ADD_REQUEST_MUTATION);

    let { parentRequestId } = useParams();

    let { revisionOrAttempt } = useParams();

    useEffect(() => {
        if(parentRequestId) {
            requestQuery({variables: {requestId: parseInt(parentRequestId)}});
        }
    }, []);

    useEffect(() => {
        if(requestQueryResult.data && requestQueryResult.data.requests[0]) {
            var requestRevisionCount = requestQueryResult.data.requests[0].revisionCount;
    
            if(revisionOrAttempt === "revision") {
                console.log("was revision")
                requestRevisionCount = requestRevisionCount + 1;
            }
            setRevisionCount(requestRevisionCount);
    
             //Attempts will start at zero, add one for display to user so that it starts at attempt 1
             var requestAttemptCount = requestQueryResult.data.requests[0].attemptCount + 1;
             if(revisionOrAttempt === "attempt") {
                 console.log("was attempt");
                 requestAttemptCount = requestAttemptCount + 1;
             }
             setAttemptCount(requestAttemptCount);

            setReviewDescription(requestQueryResult.data.requests[0].description);
            setDocumentNumber(requestQueryResult.data.requests[0].documentNumber);
            setPublicationName(requestQueryResult.data.requests[0].publicationName);
            setReviewType(requestQueryResult.data.requests[0].reviewType);
            setReminderInterval(requestQueryResult.data.requests[0].reminderInterval);
            setOriginator({text: requestQueryResult.data.requests[0].requestorUser.firstName + ' ' + requestQueryResult.data.requests[0].requestorUser.lastName, secondaryText: requestQueryResult.data.requests[0].requestorUser.email, guid: requestQueryResult.data.requests[0].requestorUser.guid, isValid: true, key: 0});
        }
    }, [requestQueryResult]);

    const onRequestSubmit = () => {
        var requestIsValid = true;
        //check that files are not too big
        var files = uploader.current.getFilesData();
        for(var i = 0; i < files.length; i++) {
            if(files[i].size > 62914560) {
                requestIsValid = false;
                alert("File size is too large. Max file size is 60MB.");
                break;
            }
        }
        if(!reviewType) {
            requestIsValid = false;
            setReviewTypeError({isError: true, errorText: "Required"});
        }
        if(!documentNumber) {
            requestIsValid = false;
            setDocumentNumberError({isError: true, errorText: "Required"});
        }
        if(!publicationName) {
            requestIsValid = false;
            setPublicationNameError({isError: true, errorText: "Required"});
        }
        if(!origionator) {
            setOrigionatorError({isError: true, errorText: "Required"});
        }
        if(!reviewers) {
            setReviewersError({isError: true, errorText: "Required"});
        }
        if(!reminderInterval) {
            setReminderIntervalError({isError: true, errorText: "Required"});
        }
        if(requestIsValid) {
            if(parentRequestId) {
                var isAttempt = false;
                var isRevision = false;
                if(revisionOrAttempt === "attempt") {
                    isAttempt = true;
                }
                else {
                    isAttempt = false;
                }
                if(revisionOrAttempt === "revision") {
                    isRevision = true;
                }
                else {
                    isRevision = false;
                }
                setUploadingNewRequest(true);
                addRequest({variables: {reviewType: reviewType, documentNumber: documentNumber, publicationName: publicationName, description: reviewDescription, origionator: origionator.guid, reviewers: reviewers.map(r => r.guid), reviewDate: reviewDate, dueDate: dueDate, reminderInterval: reminderInterval, persistent: persistent, parentRequestId: parseInt(parentRequestId), isAttempt: isAttempt, isRevision: isRevision}})
            }
            else {
                var isAttempt = false;
                var isRevision = false;
                setUploadingNewRequest(true);
                addRequest({variables: {reviewType: reviewType, documentNumber: documentNumber, publicationName: publicationName, description: reviewDescription, origionator: origionator.guid, reviewers: reviewers.map(r => r.guid), reviewDate: reviewDate, dueDate: dueDate, reminderInterval: reminderInterval, persistent: persistent, isAttempt: isAttempt, isRevision: isRevision}})
            }
            
        }
    }

    const onFilesUploadingTryAgain = (args) => {
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.jwtIdToken.idToken}`);
    }

    const onChunkUploading = (args) => {
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.jwtIdToken.idToken}`);
    }

    const onFilesUploading = (args) => {
        setFilesLoading(true);
        args.currentRequest.setRequestHeader('Authorization', `Bearer ${currentUser.jwtIdToken.idToken}`);
    }

    useEffect(() => {
        if(addRequestResult && addRequestResult.data) {
            setRequestId(addRequestResult.data.addRequest.id);
        }
    }, [addRequestResult]);

    useEffect(() => {
        if (requestId && uploadingNewRequest) {
            setFilesToUploadCount(uploader.current.getFilesData().length);
            uploader.current.asyncSettings.saveUrl = `${getBaseURL()}/fileupload/${requestId}`;
            uploader.current.upload();
            setRequestAdded(true);
            setUploadingNewRequest(false);
        }
    }, [requestId, uploadingNewRequest]);

    useEffect(() => {
        if(requestAdded && filesUploaded && fileUploadedCount === filesToUploadCount && !errorOccured) {
            setTimeout(() => {
                //redirect to tasks page
                navigate("/requests");
            }, 8000);
        }
    }, [requestAdded, filesUploaded, fileUploadedCount, errorOccured]);

    const onFilesUploadSuccess = () => {
        setFileUploadedCount((prev)=> prev + 1);
        setFilesUploaded(true);
        setFilesLoading(false);
    }

    const onFilesUploadError = (args) => {
        setFileUploadedCount((prev)=> prev + 1); //will ensure loading screen goes away
        setErrorFiles((prev) => [...prev, args.file.name])
        setFilesUploaded(false);
        setErrorOccured(true);
        setFilesLoading(false);
    }

    return(
        <Container>
            <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                <Typography variant="h5" style={{flex: 1}}>Create Request</Typography>

                {(requestQueryResult.data && requestQueryResult.data.requests[0]) &&
                    <React.Fragment>

                        <Typography variant="subtitle1">Revision: {revisionCount}</Typography>&nbsp;
                        <Typography variant="subtitle1">Attempt: {attemptCount}</Typography>
                    </React.Fragment>
                }

            </Grid>

            {/* Hiding instead of unmounting to ensure file uploader in tsk page function correctly */}
            <React.Fragment>
                <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m: 1}}} hidden={!(reviewTypeQuery.data && reminderIntervals.data) || requestAdded}>
                    {(reviewTypeQuery.data && reminderIntervals.data) &&
                    <React.Fragment>
                   <FormControl variant='standard' sx={{m:1,minWidth: 120}}>
                       <InputLabel>Review Type</InputLabel>
                       <Select
                           value={reviewType}
                           onChange={(e) => {setReviewType(e.target.value); setReviewTypeError({isError: false, errorText: ""});}}
                           error={reviewTypeError.isError}
                       >
                           {reviewTypeQuery.data.reviewType.map((item, index) => {
                               return(
                                   <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                               );
                           })}
                       </Select>
                       <FormHelperText style={{color: "red"}}>{reviewTypeError.errorText}</FormHelperText>
                   </FormControl>
                   <TextField
                        variant="outlined"
                        color="primary"
                        label="Document Number"
                        fullWidth
                        value={documentNumber}
                        onChange={(e) => {setDocumentNumber(e.target.value);}}
                        helperText="This is the system based manual number"
                        required
                        error={documentNumberError.isError}
                    />
                    {documentNumberError.isError &&
                    <Typography variant="caption" style={{color: "red"}}>Required</Typography>
                   }
                   <TextField
                       variant="outlined"
                       color="primary"
                       label="Publication Name"
                       fullWidth
                       onChange={(e) => {setPublicationName(e.target.value);}}
                       value={publicationName}
                       helperText="This is the name of the manual"
                       required
                       error={publicationNameError.isError}
                   />
                   {publicationNameError.isError &&
                    <Typography variant="caption" style={{color: "red"}}>Required</Typography>
                   }
                   {/* <Typography variant="h6">Review Description</Typography> */}
                   <TextField
                        variant="outlined"
                        color="primary"
                        label="Description"
                        fullWidth
                        onChange={(e)=> {setReviewDescription(e.target.value);}}
                        value={reviewDescription}
                        required
                        multiline
                        minRows={4}
                   />
                    {/* <RichTextEditorComponent
                           showCharCount={false}
                           toolbarSettings={toolbarSettings}
                           height="500px"
                           style={{overflow: "hidden", marginLeft: '10px', marginRight: '10px'}}
                           ref={editor => setEditor(editor)}
                       >
                           <Inject services={[syncfusionToolbar, Link, HtmlEditor, Count, QuickToolbar, Table]}/>
                   </RichTextEditorComponent> */}
                   <Typography variant="caption">{reviewType ? reviewTypeQuery.data.reviewType.find(t => t.id === reviewType).description : ""}</Typography>
                   <PeoplePicker
                       _helperText="This is the contact person for this request"
                       _peopleValue={origionator}
                       _setPeopleValue={setOriginator}
                       _label="Originator"
                       _isMultiple={false}
                       _required={true}
                       _internalUsers={false}
                       _isError={origionatorError.isError}
                       _errorText={origionatorError.errorText}
                   />
                   <PeoplePicker
                       _helperText=""
                       _peopleValue={reviewers}
                       _setPeopleValue={setReviewers}
                       _label="Reviewers"
                       _isMultiple={true}
                       _required={true}
                       _internalUsers={false}
                       _isError={reviewersError.isError}
                       _errorText={reviewersError.errorText}
                   />
                   <LocalizationProvider dateAdapter={AdapterDateFns}>
                       <DatePicker 
                           value={reviewDate} 
                           onChange={setReviewDate} 
                           label="Review Date"
                           format="MM/dd/yyyy"
                           minDate={new Date()}
                       />
                       <DatePicker 
                           value={dueDate} 
                           onChange={setDueDate} 
                           label="Review Due Date"
                           format="MM/dd/yyyy"
                           minDate={addDays(1)}
                       />
                   </LocalizationProvider>
                   <FormControl variant='standard' sx={{m:1,minWidth: 120}}>
                       <InputLabel>Reminder Interval</InputLabel>
                       <Select
                           value={reminderInterval}
                           onChange={(e) => {setReminderInterval(e.target.value)}}
                           style={{width: '150px'}}
                           error={reminderIntervalError.isError}
                       >
                           {reminderIntervals.data.reminderIntervals.map((item, index) => {
                               return(
                                   (item.days === 0) ?
                                       <MenuItem key={index} value={item.id}>No Reminder</MenuItem>
                                       :
                                       item.days === 1 ?
                                           <MenuItem key={index} value={item.id}>{item.days} Day</MenuItem>
                                       :
                                           <MenuItem key={index} value={item.id}>{item.days} Days</MenuItem>
                              );
                           })}
                       </Select>
                       <FormHelperText style={{color: "red"}}>{reminderIntervalError.errorText}</FormHelperText>
                   </FormControl>
                   </React.Fragment>
                   }
                   <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m: 1}}} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <Typography variant="h6">My Files</Typography>
                            <span>(*max file upload size is 60MB.)</span>
                        </div>
                        <UploaderComponent 
                            autoUpload={false}
                            maxFileSize={62914560}//60MB
                            type = 'file'
                            ref={uploader}
                            style={{backgroundColor: "blue"}}
                            chunkUploading={onChunkUploading}
                            asyncSettings = {{chunkSize: chunkSize, retryCount: maxRetries, saveUrl: `${getBaseURL()}/fileupload/${requestId}`}}
                            uploading={onFilesUploading}
                            id='fileUpload'
                            cssClass='hideUploadButton'
                            success={onFilesUploadSuccess}
                            failure={onFilesUploadError}
                        />
                    </Paper>
                   <Button
                       color="primary"
                       variant="contained"
                       onClick={onRequestSubmit}
                       disabled={addRequestResult.loading}
                       sx={{...(requestAdded && 
                                {backgroundColor: green[500], '&:hover':{backgroundColor: green[700]}}
                                )}} //conditionally add style
                   >
                       {(addRequestResult.loading || filesLoading) && <CustomCircularProgress />}
                       Submit
                   </Button>
                   <Button
                        variant="outlined"
                        style={{
                            borderColor: "#ba000d", 
                            color: "#ba000d",
                            marginLeft: '10px'
                        }}
                        onClick={() => {
                            if(parentRequestId) {
                                navigate("/viewrequest/" + parentRequestId);
                            }
                            else {
                                navigate("/");
                            }
                        }}
                   >
                       Cancel
                   </Button>
                   {(reviewTypeError.isError || documentNumberError.isError || publicationNameError.isError || origionatorError.isError || reviewersError.isError || reminderIntervalError.isError) &&
                    <Typography variant="subtitle1" style={{color: "red"}}>Fill in all required fields</Typography>
                   }
               </Paper>
               {!(reviewTypeQuery.data && reminderIntervals.data) &&
               <CircularProgress color="primary" hidden={reviewTypeQuery.data && reminderIntervals.data}/>}
            </React.Fragment>

            {requestAdded && fileUploadedCount !== filesToUploadCount ?
                <React.Fragment>
                    <Alert severity="success">Request Submitted. Please wait for files to upload. Files over 30 MB may take longer to upload.</Alert>
                    <br />
                    <br />
                    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                            <CircularProgress color="primary"/>
                            <Typography variant="h6" style={{marginLeft: '10px'}}>Uploading New Files...</Typography>
                            <br></br>
                            <Typography variant="h6" style={{marginLeft: '10px'}}>{fileUploadedCount}/{filesToUploadCount} Uploaded</Typography>
                    </div>
                </React.Fragment>
            :
            requestAdded && (errorOccured || !filesUploaded) ?
                <React.Fragment>
                    { filesToUploadCount > 0 ?
                    <Alert severity="error">The request has been submitted, but an error occurred while uploading the new files. The file(s) named {errorFiles.join(", and ")} failed to upload. Please use the tool below to try to upload again. You can leave this page once your files are uploaded.</Alert>
                    : <Alert severity="warning">The request has been submitted, but no files were provided. Please upload files now. You can leave this page once your files are uploaded.</Alert> }
                    <br />
                    <br />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Typography variant="h6">My Files</Typography>
                        <span>(*max file upload size is 60MB.)</span>
                    </div>
                    <Paper sx={{px:2,py:2, '& .MuiTextField-root': {m: 1}}}>
                        <UploaderComponent 
                            autoUpload={false}
                            maxFileSize={62914560}//60MB
                            type = 'file'
                            chunkUploading={onFilesUploadingTryAgain}
                            ref={fileUpload => setFileUploader(fileUpload)}
                            style={{backgroundColor: "blue"}}
                            asyncSettings = {{chunkSize: chunkSize, retryCount: maxRetries, saveUrl: `${getBaseURL()}/fileupload/${requestId}`}}
                            uploading={onFilesUploadingTryAgain}
                            id='fileUpload'
                            //success={onFilesUploadSuccess}
                            //failure={onFilesUploadError}
                        />
                        <Typography style={{marginTop: '10px'}} variant="subtitle1">Files are uploaded when "File uploaded successfully" is shown after clicking upload. 100% means the files are still uploading.</Typography>
                    </Paper>
                </React.Fragment>
            :
            requestAdded && filesUploaded &&
                <React.Fragment>
                    <Alert severity="success">New Files Have Been Added Successfully</Alert>
                    {revisionOrAttempt === "attempt" &&
                        <Typography variant="h6">Files from previous request have been copied to new request. Any new files have submitted successfully.</Typography>
                    }
                    <br />
                    <br />
                    <Typography variant="h5">You will be redirected to the Requests page momentarily...</Typography>
                </React.Fragment>
            }
        </Container>
    );
}