import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { 
    Switch,
    Typography,
    FormControlLabel,
    FormGroup
  } from '@mui/material';

  export const UserPreferences = (userPreferences, setUserPreferences) => {
        const UPDATE_USER_PREFERENCES = gql`
          mutation UpsertUserPreference($preferenceId: Int!, $value: Boolean!) {
            upsertUserPreference(preferenceId: $preferenceId, value: $value) {
              preferenceId,
              value,
              preferenceType {
                name
              }
            }
          }
        `;

        const preferenceIsLoading= (preferenceId) => {
          try{
            return userPreferences.find(p => p.id === preferenceId).loading;
          }
          catch {
            return false;
          }
        }

        const [userPreferenceMutation, userPreferenceMutationResult] = useMutation(UPDATE_USER_PREFERENCES);

        const onPreferenceChange = (preferenceId, event) => {
          console.log("event", event)
          console.log("preference id: " + preferenceId);
            var userPreferencesCopy = [...userPreferences.userPreferences];
            userPreferencesCopy.find(p => p.preferenceId === preferenceId).value = event.target.checked;
            userPreferencesCopy.find(p => p.preferenceId === preferenceId).loading = true;
            userPreferences.setUserPreferences(userPreferencesCopy);

            userPreferencesCopy = [...userPreferences.userPreferences];

            userPreferenceMutation({variables: {preferenceId: preferenceId, value: event.target.checked}})
            .then(() => {
              userPreferencesCopy.find(p => p.preferenceId === preferenceId).loading = false;
              userPreferencesCopy.find(p => p.preferenceId === preferenceId).value = event.target.checked;
              userPreferences.setUserPreferences(userPreferencesCopy);
            })
            .catch(() => {
              userPreferencesCopy.find(p => p.preferenceId === preferenceId).loading = false;
              userPreferencesCopy.find(p => p.preferenceId === preferenceId).value = !event.target.checked;
              userPreferences.setUserPreferences(userPreferencesCopy);
            });
        }

        return(
            userPreferences.userPreferences.length > 0 ?
                <FormGroup>
                    {userPreferences.userPreferences.map((item, index) => {
                        return(
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={item.value}
                                        onChange={(e) => {onPreferenceChange(item.preferenceId, e)}}
                                        color="primary"
                                        disabled={preferenceIsLoading(item.preferenceId)}
                                    />
                                }
                                label={item.preferenceType.name}
                            />
                        )
                    })}
                </FormGroup>
            :
            <Typography variant="subtitle1">Error Loading Preferences</Typography>
        );
  }
  export default UserPreferences;