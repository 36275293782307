import React from "react";
import {
    Box,
    ClickAwayListener,
    Fade,
    Paper,
    Popper,
} from "@mui/material";

//Component from https://codesandbox.io/s/popper-with-arrow-58jhe?file=/src/RichTooltip.tsx:341-2510

  export const RichTooltip = ({
    placement = "top",
    arrow = true,
    open,
    onClose = () => {},
    content,
    children
  }) => {

    const [arrowRef, setArrowRef] = React.useState(null);
    const [childNode, setChildNode] = React.useState(null);

    return (
      <div>
        {React.cloneElement(children, { ...children.props, ref: setChildNode })}
        <Popper
          open={open}
          anchorEl={childNode}
          placement={placement}
          transition
          sx={{
            // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
            zIndex: 2000,
            '&[data-popper-placement*="bottom"] .arrow': {
              top: 0,
              left: 0,
              marginTop: "-0.71em",
              marginLeft: 4,
              marginRight: 4,
              "&::before": {
                transformOrigin: "0 100%"
              }
            },
            '&[data-popper-placement*="top"] .arrow': {
              bottom: 0,
              left: 0,
              marginBottom: "-0.71em",
              marginLeft: 4,
              marginRight: 4,
              "&::before": {
                transformOrigin: "100% 0"
              }
            },
            '&[data-popper-placement*="right"] .arrow': {
              left: 0,
              marginLeft: "-0.71em",
              height: "1em",
              width: "0.71em",
              marginTop: 4,
              marginBottom: 4,
              "&::before": {
                transformOrigin: "100% 100%"
              }
            },
            '&[data-popper-placement*="left"] .arrow': {
              right: 0,
              marginRight: "-0.71em",
              height: "1em",
              width: "0.71em",
              marginTop: 4,
              marginBottom: 4,
              "&::before": {
                transformOrigin: "0 0"
              }
            }
          }}

          modifiers={[{
            preventOverflow: {
              enabled: true,
              boundariesElement: "window"
            },
            arrow: {
              enabled: arrow,
              element: arrowRef
            }
          }]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={onClose}>
                  <Paper sx={{backgroundColor: 'background.paper', maxWidth: 1000}}>
                    {arrow ? (
                      <span 
                        className="arrow"
                        sx={{
                          // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
                          overflow: "hidden",
                          position: "absolute",
                          width: "1em",
                          height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
                          boxSizing: "border-box",
                          color: 'background.paper',
                          "&::before": {
                            content: '""',
                            margin: "auto",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            boxShadow: 1,
                            backgroundColor: "currentColor",
                            transform: "rotate(45deg)"
                        }}} 
                        ref={setArrowRef} />
                    ) : null}
                    <Box sx={{p:2}}>{content}</Box>
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  };

  export default RichTooltip;